import React from "react";
import "../../styles/components/modal-component.css"; // Import modal-specific styles

const Modal = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null;

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        {/* "X" button for closing */}
        <button onClick={onClose} className="modal-close-x no-hover" aria-label="Close">
  &times;
</button>

        {/* Render children inside the modal */}
        {children}
      </div>
    </div>
  );
};

export default Modal;
