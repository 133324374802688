// FetchLogoImage.js
import { getStorage, ref, getDownloadURL, listAll } from 'firebase/storage'; // Add 'listAll'

const FetchLogoImage = async (gatheringId) => {
    const storage = getStorage();
    const LogoFolderRef = ref(storage, `gatherings/${gatheringId}/logo`);

    try {
        const res = await listAll(LogoFolderRef);
        //console.log("Storage list response: ", res);  // Log the response from listAll
        if (res.items.length > 0) {
            const url = await getDownloadURL(res.items[0]);

            // console.log("Logo image URL: ", url);  // Log the URL retrieved
            return url;
        } else {
            console.log("No images found in the logo folder.");
            return null;
        }
    } catch (error) {
        console.error("Failed to fetch logo image: ", error);
        return null; // Return null if there's an error
    }
};

export default FetchLogoImage;
