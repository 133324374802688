import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import imageConversions from "../logic/imageConversion";

const uploadImage = async (file, entityID, entityType) => {
  if (!file) return null;

  let baseDirectory, filename;

  switch (entityType.toLowerCase()) {
    case "artist":
      baseDirectory = "artistImages"; // Directory for artist images
      filename = "artistImage.jpeg";  // Always "artistImage.jpeg" for artist
      break;
    case "vendor":
      baseDirectory = "vendorImages"; // Directory for vendor images
      filename = "vendorImage.jpeg";  // Always "vendorImage.jpeg" for vendor
      break;
    case "gathering-map":
      baseDirectory = `gatherings/${entityID}/map`; // Gathering map image
      filename = "mapImage.jpeg";  // Always "mapImage.jpeg" for gathering map
      break;
    case "gathering-logo":
      baseDirectory = `gatherings/${entityID}/logo`; // Gathering logo image
      filename = "logoImage.jpeg";  // Always "logoImage.jpeg" for gathering logo
      break;
    default:
      throw new Error(`Invalid entity type: ${entityType}`);
  }

  // Construct the full path to upload the file
  const storage = getStorage();
  const storageRef = ref(storage, `${baseDirectory}/${filename}`);

  try {
    let fileToUpload = file;

    // Convert image to JPEG if it's not already a JPEG
    if (!file.name.endsWith(".jpg") && !file.name.endsWith(".jpeg")) {
      const imageDataUrl = await imageConversions.imageToDataURL(file); // Convert image to data URL
      fileToUpload = imageConversions.dataURLtoFile(imageDataUrl, filename); // Convert data URL back to File object
    }

    // Upload the file to Firebase Storage
    const snapshot = await uploadBytes(storageRef, fileToUpload);
    const url = await getDownloadURL(snapshot.ref);  // Get the file's download URL

    console.log(`File uploaded successfully: ${url}`);
    return url;  // Return the download URL of the uploaded image
  } catch (error) {
    console.error("Failed to upload image", error);
    return null; // Return null in case of an error
  }
};

export default uploadImage;
