import { getFirestore, collection, query, where, getDocs } from 'firebase/firestore';
import getGatheringStatus from '../logic/getGatheringStatus';

// Initialize Firestore
const firestore = getFirestore();

// Function to check both shortcode and gathering name availability
const checkAvailability = async (shortcode, gatheringName, startDate, endDate, currentGatheringID) => {
  try {
    console.log('Checking availability for shortcode:', shortcode); 
    console.log('Checking availability for gathering name:', gatheringName);

    const gatheringsRef = collection(firestore, 'Gatherings');

    // Query for gatherings with the same shortcode and gathering name
    const shortcodeQuery = query(gatheringsRef, where('Shortcode', '==', shortcode));
    const gatheringNameQuery = query(gatheringsRef, where('GatheringName', '==', gatheringName));

    const shortcodeSnapshot = await getDocs(shortcodeQuery);
    const gatheringNameSnapshot = await getDocs(gatheringNameQuery);

    let isShortcodeAvailable = true;
    let isGatheringNameAvailable = true;

    // Check for upcoming or ongoing gatherings with the same shortcode
    for (const doc of shortcodeSnapshot.docs) {
      const gatheringData = doc.data();
      const gatheringStatus = getGatheringStatus(gatheringData.StartDate, gatheringData.EndDate);

      // Skip the availability check if this is the same gathering (i.e., gatheringID matches currentGatheringID)
      if (doc.id === currentGatheringID) {
        console.log('Shortcode is in use by the same gathering, skipping check.');
        continue;
      }

      if (gatheringStatus === 'upcoming' || gatheringStatus === 'ongoing') {
        isShortcodeAvailable = false;
        console.log('Shortcode is already in use for an upcoming or ongoing gathering.');
        break;
      }
    }

    // Check for upcoming or ongoing gatherings with the same gathering name
    for (const doc of gatheringNameSnapshot.docs) {
      const gatheringData = doc.data();
      const gatheringStatus = getGatheringStatus(gatheringData.StartDate, gatheringData.EndDate);

      // Skip the availability check if this is the same gathering (i.e., gatheringID matches currentGatheringID)
      if (doc.id === currentGatheringID) {
        console.log('Gathering name is in use by the same gathering, skipping check.');
        continue;
      }

      if (gatheringStatus === 'upcoming' || gatheringStatus === 'ongoing') {
        isGatheringNameAvailable = false;
        console.log('Gathering name is already in use for an upcoming or ongoing gathering.');
        break;
      }
    }

    return { isShortcodeAvailable, isGatheringNameAvailable };
  } catch (error) {
    console.error('Error checking availability:', error);
    throw new Error(`Failed to check availability: ${error.message}`);
  }
};

export default checkAvailability;
