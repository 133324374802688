import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { auth } from "../../config/FirebaseConfig";
import "../../styles/components/participant-registration.css";
import CreateParticipant from "../../api/CreateParticipant"; // Unified function
import AddVendorToGathering from "../../api/AddVendorToGathering";
import AddArtistToGathering from "../../api/AddArtistToGathering"; // Functions for adding to Gathering
import { ParticipantSchema } from "../../data/validationSchemas/ParticipantSchema"; // Unified schema
import { z } from "zod";

const ParticipantRegistration = ({ onClose, addNewParticipant, type }) => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    website: "",
    description: "",
    gatheringID: "",
  });

  const [errors, setErrors] = useState({});
  const navigate = useNavigate();

  const validateInputs = () => {
    try {
      // Construct dataToValidate with unified field names
      const dataToValidate = {
        ...formData,
        userID: auth.currentUser ? auth.currentUser.uid : "",
        referenceID: "generatedReferenceID", // Placeholder for validation; replace with actual ID if available
        gatheringReferenceIDs: formData.gatheringID ? [formData.gatheringID] : [],
      };

      // Validate data using the unified ParticipantSchema
      ParticipantSchema.omit({ id: true }).parse(dataToValidate);
      setErrors({});
      return true;
    } catch (error) {
      if (error instanceof z.ZodError) {
        const newErrors = error.errors.reduce((acc, err) => {
          acc[err.path[0]] = err.message;
          return acc;
        }, {});
        setErrors(newErrors);
        console.log("Validation errors: ", newErrors);
      }
      return false;
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const currentUser = auth.currentUser;
    if (!currentUser) {
      alert("You must be logged in to register.");
      return;
    }
  
    if (validateInputs()) {
      try {
        const formDataWithUser = {
          ...formData,
          userID: currentUser.uid,
        };
  
        // Create the participant profile
        const participantId = await CreateParticipant(formDataWithUser, type);
  
        if (participantId) {
          // if (formData.gatheringID) {
          //   // If a Gathering ID is provided, try to add the participant to that gathering
          //   const addToGathering =
          //     type === "Vendor" ? AddVendorToGathering : AddArtistToGathering;
  
          //   const added = await addToGathering(participantId, formData.gatheringID);
  
          //   if (added) {
          //     alert(`${type} registered and added to gathering successfully!`);
          //   } else {
          //     alert(`${type} registered, but failed to add to gathering.`);
          //   }
          // } else {
          //   // No Gathering ID provided, simply register the participant
          //   alert(`${type} registered successfully without a gathering.`);
          // }
  
          // Route the user to their dashboard after registration
          navigate(`/${type.toLowerCase()}-dashboard/${participantId}`);
          onClose();
        }
      } catch (error) {
        console.error(`Error processing ${type} registration: `, error);
        alert(`Error registering ${type} or updating gathering.`);
      }
    } else {
      alert("Please correct the errors before submitting.");
    }
  };
  

  return (
    <div className="participant-registration-container">
      <h2>Register your {type} Profile</h2>
      <form onSubmit={handleSubmit} className="participant-input-container">
        <input
          type="text"
          name="name"
          value={formData.name}
          onChange={handleChange}
          placeholder="Artist/Vendor Name"
          required
          className="participant-input-field"
        />
        {errors.name && <p className="error-message">{errors.name}</p>}
        <input
          type="email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          placeholder="Email"
          required
          className="participant-input-field"
        />
        {errors.email && <p className="error-message">{errors.email}</p>}
        <input
          type="url"
          name="website"
          value={formData.website}
          onChange={handleChange}
          placeholder="Website URL"
          className="participant-input-field"
        />
        {errors.website && <p className="error-message">{errors.website}</p>}
        <textarea
          name="description"
          value={formData.description}
          onChange={handleChange}
          placeholder="Description"
          className="participant-input-field"
        />
        <p>
          Did an Event share their reference code with you? If so, enter it below
          to join their Event (not required)
        </p>
        <input
          type="text"
          name="gatheringID"
          value={formData.gatheringID}
          onChange={handleChange}
          placeholder="Gathering ID"
          className="participant-input-field"
        />
        {errors.description && (
          <p className="error-message">{errors.description}</p>
        )}
        <button type="submit" className="participant-submit-button">
          Register
        </button>
      </form>
    </div>
  );
};

export default ParticipantRegistration;
