import { doc, deleteDoc } from "firebase/firestore";
import { firestore } from "../config/FirebaseConfig";

const DeleteAnnouncement = async (announcementId: string): Promise<boolean> => {
  console.log("Attempting to delete announcement with ID:", announcementId);
  try {
    await deleteDoc(doc(firestore, "Announcements", announcementId));
    console.log("Announcement deleted with ID:", announcementId);
    return true; // Indicate success
  } catch (error) {
    console.error("Error deleting announcement:", error);
    return false; // Indicate failure
  }
};

export default DeleteAnnouncement;
