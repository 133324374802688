import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";

import FetchParticipant from "../../api/FetchParticipant";
import "../../styles/views/participant-dashboard-view.css";
import ModifyParticipant from "../../api/ModifyParticipant";
import FetchParticipantGatherings from "../../api/FetchParticipantGatherings";
import Modal from "../../components/Modal/Modal";
import { PartialParticipantSchema } from "../../data/validationSchemas/ParticipantSchema";
import ModifyParticipantGatherings from "../../api/ModifyParticipantGatherings";
import uploadImage from "../../api/UploadImage";
import DeleteExistingImage from "../../api/DeleteExistingImage";

const ParticipantDashboard = ({ type }) => {
  const { participantID } = useParams();
  const navigate = useNavigate();

  const [participantData, setParticipantData] = useState({
    name: "",
    email: "",
    website: "",
    description: "",
    gatheringIDs: [],
    participantReferenceID: "",
    imagePath: "",
    socialLinks: [], // New field for social links
  });
  const [newSocialLink, setNewSocialLink] = useState({ name: "", url: "" }); // New social link input
  const [gatherings, setGatherings] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newGatheringReferenceID, setNewGatheringReferenceID] = useState("");
  const [isEditable, setIsEditable] = useState(false);
  const [imageFile, setImageFile] = useState(null);
  const [errors, setErrors] = useState({});
  const [refreshCounter, setRefreshCounter] = useState(0);

  useEffect(() => {
    const loadData = async () => {
      if (participantID) {
        const participant = await FetchParticipant(participantID, type);
        if (participant) {
          const gatheringIDsArray = Array.isArray(
            participant.gatheringIDs
          )
            ? participant.gatheringIDs
            : Object.values(participant.gatheringIDs || {});

          setParticipantData({
            name: participant.name,
            email: participant.email,
            website: participant.website || "",
            description: participant.description || "",
            gatheringIDs: gatheringIDsArray,
            participantReferenceID: participant.referenceID,
            imagePath: participant.imagePath || "",
            socialLinks: participant.socialLinks || [], // Load social links
          });

          if (gatheringIDsArray.length !== 0) {
            const fetchedGatherings = await FetchParticipantGatherings(
              gatheringIDsArray
            );
            setGatherings(fetchedGatherings);
          }
        }
      }
    };
    loadData();
  }, [participantID, refreshCounter]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setParticipantData((prev) => ({ ...prev, [name]: value }));

    if (errors[name]) {
      setErrors((prev) => ({ ...prev, [name]: "" }));
    }
  };

  const toggleEditMode = () => {
    setIsEditable(!isEditable);
  };

  const handleSocialLinkChange = (field, value) => {
    setNewSocialLink((prev) => ({ ...prev, [field]: value }));
  };

  const addSocialLink = () => {
    if (!newSocialLink.name || !newSocialLink.url) {
      alert("Please fill in both fields.");
      return;
    }

    setParticipantData((prev) => ({
      ...prev,
      socialLinks: [...prev.socialLinks, newSocialLink],
    }));
    setNewSocialLink({ name: "", url: "" }); // Reset inputs
  };

  const removeSocialLink = (index) => {
    setParticipantData((prev) => ({
      ...prev,
      socialLinks: prev.socialLinks.filter((_, i) => i !== index),
    }));
  };

  const renderSocialLinkOptions = () => {
    if (type === "Artist") {
      return (
        <>
          <option value="">Select Platform</option>
          <option value="Spotify">Spotify</option>
          <option value="Apple Music">Apple Music</option>
          <option value="TIDAL">TIDAL</option>
          <option value="Bandcamp">Bandcamp</option>
          <option value="Soundcloud">Soundcloud</option>
          <option value="Other">Other</option>
        </>
      );
    } else {
      return (
        <>
          <option value="">Select Platform</option>
          <option value="Facebook">Facebook</option>
          <option value="Twitter">Twitter</option>
          <option value="LinkedIn">LinkedIn</option>
          <option value="Instagram">Instagram</option>
          <option value="Other">Other</option>
        </>
      );
    }
  };

  const saveChanges = async () => {
    try {
      // Handle image upload if an image file is selected
      if (imageFile) {
        try {
          // Determine the directory based on participant type
          const entityType = type.toLowerCase(); // "artist" or "vendor"

          // Delete existing image before uploading the new one
          await DeleteExistingImage(participantID, entityType);

          // Upload the new image
          const imagePath = await uploadImage(
            imageFile,
            participantID,
            entityType
          );
          participantData.imagePath = imagePath; // Update the participant data with the uploaded image path
        } catch (error) {
          console.error("Error uploading image:", error);
          alert("Failed to upload the image. Please try again.");
          return; // Stop saving if image upload fails
        }
      }

      // Validate participant data using Zod schema
      const result = PartialParticipantSchema.safeParse(participantData);
      if (!result.success) {
        const validationErrors = result.error.errors.reduce((acc, err) => {
          acc[err.path[0]] = err.message;
          return acc;
        }, {});
        setErrors(validationErrors);
        alert("Please correct the errors before saving.");
        return;
      }

      // Save participant data using the ModifyParticipant API
      const success = await ModifyParticipant(
        participantID,
        participantData,
        type
      );
      if (success) {
        alert("Changes saved successfully.");
        setIsEditable(false); // Disable edit mode after successful save
      } else {
        alert("Failed to save changes.");
      }
    } catch (error) {
      console.error("An error occurred while saving changes:", error);
      alert("An unexpected error occurred. Please try again.");
    }
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    setImageFile(file);
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setNewGatheringReferenceID("");
  };

  const handleAddGathering = async () => {
    if (!newGatheringReferenceID) {
      alert("Please enter a Gathering Reference ID.");
      return;
    }
  
    const participantReferenceID = participantData.participantReferenceID;
  
    try {
      const result = await ModifyParticipantGatherings(
        participantReferenceID,
        newGatheringReferenceID,
        type,
        "add" // Action
      );
  
      if (result.success) {
        alert("Gathering added successfully.");
  
        // Use the returned actual document ID (result.gatheringID) from the Firebase function
        setParticipantData((prevData) => ({
          ...prevData,
          gatheringIDs: [
            ...(prevData.gatheringIDs || []), // Update the array in local state
            result.gatheringID, // Add the actual document ID
          ],
        }));
        setRefreshCounter((prev) => prev + 1); // Refresh data
        closeModal();
      } else {
        alert("Failed to add the Gathering.");
      }
    } catch (error) {
      console.error("Error adding gathering:", error);
      alert("An unexpected error occurred.");
    }
  };
  

  const handleRemoveParticipant = async (
    participantReferenceID,
    gatheringReferenceID
  ) => {
    try {
      const result = await ModifyParticipantGatherings(
        participantReferenceID,
        gatheringReferenceID,
        type,
        "remove"
      );
      if (result.success) {
        alert("Gathering removed successfully.");
        setParticipantData((prevData) => ({
          ...prevData,
          gatheringReferenceIDs: prevData.gatheringReferenceIDs.filter(
            (id) => id !== gatheringReferenceID
          ),
        }));
      } else {
        alert("Failed to remove the Gathering.");
      }
    } catch (error) {
      console.error("Error removing gathering:", error);
      alert("An unexpected error occurred.");
    }
  };

  return (
<div className="participant-dashboard-container">
  <button className="return-home-button" onClick={() => navigate("/")}>
    Go back
  </button>
  <h2 className="dashboard-title">{type} Dashboard</h2>
  <div className="dashboard-content">
    <div className="participant-content">
      <div className="rounded-div participant-details">
        <h3>{type} Details</h3>
        <button className="edit-mode-button" onClick={toggleEditMode}>
          {isEditable ? "Disable Editing" : "Enable Editing"}
        </button>
        {isEditable && <button onClick={saveChanges}>Save Changes</button>}

        <input
          className="input-field"
          type="text"
          name="name"
          placeholder={`${type} Name`}
          value={participantData.name || ""}
          onChange={handleInputChange}
          disabled={!isEditable}
        />
        {errors.name && <p className="error-message">{errors.name}</p>}

        <input
          className="input-field"
          type="email"
          name="email"
          value={participantData.email || ""}
          onChange={handleInputChange}
          disabled={!isEditable}
        />
        {errors.email && <p className="error-message">{errors.email}</p>}

        <input
          className="input-field"
          type="url"
          name="website"
          value={participantData.website || ""}
          onChange={handleInputChange}
          disabled={!isEditable}
        />
        {errors.website && <p className="error-message">{errors.website}</p>}

        <textarea
          className="input-field"
          name="description"
          value={participantData.description || ""}
          onChange={handleInputChange}
          disabled={!isEditable}
        />
        {errors.description && (
          <p className="error-message">{errors.description}</p>
        )}

        <div>
          <h4>Social Links:</h4>
          {participantData.socialLinks.map((link, index) => (
            <div key={index} className="social-link">
              <span>
                <strong>{link.name}:</strong> {link.url}
              </span>
              {isEditable && (
                <button onClick={() => removeSocialLink(index)}>Remove</button>
              )}
            </div>
          ))}
          {isEditable && (
            <div className="social-link-inputs">
              <select
                className="input-field"
                value={newSocialLink.name}
                onChange={(e) => handleSocialLinkChange("name", e.target.value)}
              >
                {renderSocialLinkOptions()}
              </select>
              <input
                className="input-field"
                type="url"
                placeholder="Enter URL"
                value={newSocialLink.url}
                onChange={(e) => handleSocialLinkChange("url", e.target.value)}
              />
              <button onClick={addSocialLink}>Add</button>
            </div>
          )}
        </div>

        {isEditable && (
          <div>
            <label htmlFor="image-upload">Upload Profile Image:</label>
            <input
              type="file"
              id="image-upload"
              onChange={handleImageChange}
              accept="image/*"
            />
            {imageFile && (
              <p>
                <strong>Selected File:</strong> {imageFile.name}
              </p>
            )}
          </div>
        )}

        {participantData.imagePath && (
          <div>
            <h4>Current Profile Image:</h4>
            <img
              src={participantData.imagePath}
              alt="Profile"
              className="profile-image"
            />
          </div>
        )}
      </div>

      <div className="rounded-div participant-gatherings">
        <div className="header">
          <h2>Your Registered Events</h2>
          <button onClick={openModal}>Add a New Event</button>
        </div>
        <table>
          <thead style={{ backgroundColor: "#E5F8FF" }}>
            <tr>
              <th>Name</th>
              <th>Date</th>
              <th>Location</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {gatherings.map((gathering) => (
              <tr key={gathering.id}>
                <td>{gathering.gatheringName}</td>
                <td>
                  {gathering.startDate} - {gathering.endDate}
                </td>
                <td>{gathering.location}</td>
                <td>
                  <button
                    onClick={() =>
                      handleRemoveParticipant(
                        participantData.participantReferenceID,
                        gathering.referenceID
                      )
                    }
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  </div>

  {/* Modal Component */}
  <Modal isOpen={isModalOpen} onClose={closeModal}>
    <h4>Add Gathering by Reference ID</h4>
    <p>Contact the Event Host for the unique Reference ID.</p>
    <input
      type="text"
      className="input-field"
      value={newGatheringReferenceID}
      onChange={(e) => setNewGatheringReferenceID(e.target.value)}
      placeholder="Enter Gathering Reference ID"
    />
    <button onClick={handleAddGathering}>Submit</button>
  </Modal>
</div>

  );
};

export default ParticipantDashboard;
