import React from "react";
import { QRCodeCanvas } from "qrcode.react";

/**
 * GatheringQRCode Component
 * @param {string} dynamicLink - The dynamic link from which the QR code is derived.
 */
const GatheringQRCode = ({ dynamicLink }) => {
  if (!dynamicLink) {
    
    return <p>QR code not available</p>;
  }

  return (
    <div>
      <h3>QR Code</h3>
      <QRCodeCanvas value={dynamicLink} size={200} />
    </div>
  );
};

export default GatheringQRCode;

