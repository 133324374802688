import { z } from 'zod';

export const EventSchema = z.object({
  id: z.string().optional(),
  eventName: z.string()
    .min(5, "Event Name must be at least 5 characters long.")
    .max(50, "Event Name must be less than 50 characters long."),
  description: z.string()
    .max(200, "Description must be less than 200 characters long."),
  location: z.string()
    .min(2, "Location must be at least 2 characters long.")
    .max(100, "Location must be less than 100 characters long."),
  startDate: z.string()
    .min(1, "Start Date must be provided."),
  startTime: z.string()
    .min(1, "Start Time must be provided."),
  endTime: z.string()
    .min(1, "End Time must be provided."),
  gatheringID: z.string(),
});

export type Event = z.infer<typeof EventSchema>;
