export class Participant {
  id: string;
  name: string;
  userID: string;
  email: string;
  referenceID: string;
  type: "Vendor" | "Artist";
  website?: string;
  description?: string;
  gatheringReferenceIDs: string[];
  imagePath?: string; // New property for the image path
  socialLinks: { name: string; url: string }[]; // New field for social links


  constructor(
    id: string,
    name: string,
    userID: string,
    email: string,
    referenceID: string,
    type: "Vendor" | "Artist",
    website?: string,
    description?: string,
    gatheringReferenceIDs: string[] = [],
    imagePath?: string,
    socialLinks: { name: string; url: string }[] = [] // Initialize as empty array
  ) {
    this.id = id;
    this.name = name;
    this.userID = userID;
    this.email = email;
    this.referenceID = referenceID;
    this.type = type;
    this.website = website;
    this.description = description;
    this.gatheringReferenceIDs = gatheringReferenceIDs;
    this.imagePath = imagePath;
    this.socialLinks = socialLinks;

  }
}
