import { doc, updateDoc, arrayUnion } from "firebase/firestore";
import { firestore } from "../config/FirebaseConfig";
import toPascalCase from "../logic/toPascalCase";
import { PartialParticipantSchema } from "../data/validationSchemas/ParticipantSchema";

interface ParticipantUpdate {
  name?: string;
  email?: string;
  website?: string;
  description?: string;
  gatheringReferenceIDs?: string[];
  imagePath?: string; // New field for the image path
}

const ModifyParticipant = async (
  participantID: string,
  updatedData: Partial<ParticipantUpdate>,
  type: "Vendor" | "Artist"
): Promise<boolean> => {
  console.log(`Modifying ${type} `, JSON.stringify(updatedData));

  // Validate updatedData using the ParticipantSchema
  const result = PartialParticipantSchema.safeParse(updatedData);
  if (!result.success) {
    console.error("Validation failed details:", result.error.issues);
    return false; // Exit if validation fails
  }

  // Convert valid updatedData to PascalCase for Firestore
  const data = toPascalCase(result.data);
  console.log("PascalCase: " + JSON.stringify(data));

  const collectionName = type === "Vendor" ? "Vendors" : "Artists";

  try {
    const participantRef = doc(firestore, collectionName, participantID);

    // Conditionally update `GatheringReferenceIDs` if provided and non-empty
    if (updatedData.gatheringReferenceIDs && updatedData.gatheringReferenceIDs.length > 0) {
      await updateDoc(participantRef, {
        GatheringReferenceIDs: arrayUnion(...updatedData.gatheringReferenceIDs),
      });
    }

    // Update other data normally, including ImagePath
    await updateDoc(participantRef, data);
    console.log(`${type} updated with ID: `, participantID);
    return true;
  } catch (error) {
    console.error(`Error updating ${type}: `, error);
    return false;
  }
};

export default ModifyParticipant;
