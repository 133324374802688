import React, { useState, useEffect } from "react";
import AnnouncementForm from "../AnnouncementForm/AnnouncementForm";
import ModifyAnnouncement from "../../api/ModifyAnnouncement";
import DeleteAnnouncement from "../../api/DeleteAnnouncement";
import FetchAnnouncements from "../../api/FetchAnnouncements";

import "../../styles/components/gathering-customization-form-component.css";

const AnnouncementsTab = ({ gatheringID }) => {
  const [announcements, setAnnouncements] = useState([]);
  const [deletedMessage, setDeletedMessage] = useState(""); // New state for deleted message

  const fetchAnnouncements = async () => {
    const announcements = await FetchAnnouncements(gatheringID);
    setAnnouncements(announcements);
  };

  useEffect(() => {
    fetchAnnouncements();
  }, [gatheringID]);

  const handleDelete = async (announcementId) => {
    const success = await DeleteAnnouncement(announcementId);
    if (success) {
      setAnnouncements((prevAnnouncements) =>
        prevAnnouncements.filter((announcement) => announcement.id !== announcementId)
      );
      console.log("Announcement deleted and state updated");
      setDeletedMessage("Announcement Deleted"); // Set delete message
    } else {
      console.log("Failed to delete announcement");
    }
  };

  const handleNewAnnouncement = (newAnnouncement) => {
    setAnnouncements((prevAnnouncements) => [
      ...prevAnnouncements,
      newAnnouncement,
    ]);
  };

  return (
    <div className="input-container">
      <h3 className="tab-title">Announcements</h3>

      <AnnouncementForm
        gatheringID={gatheringID}
        onNewAnnouncement={handleNewAnnouncement}
      />
      <div className="input-container">
        <h3>Existing Announcements</h3>
        {deletedMessage && <p className="deleted-message">{deletedMessage}</p>} {/* Show deleted message */}

        <ul className="announcements-list">
          {announcements.map((announcement) => (
            <li key={announcement.id}>
              <h3>{announcement.title}</h3>
              <p>{announcement.content}</p>
              {/* <button onClick={() => handleModify(announcement.id)}>
                Modify
              </button> */}
              <button
                className="delete-button"
                onClick={() => handleDelete(announcement.id)}
              >
                Delete
              </button>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default AnnouncementsTab;
