import { doc, updateDoc, arrayUnion } from "firebase/firestore";
import { firestore } from "../config/FirebaseConfig"; // Correct Firestore instance reference

const AddTeamMemberToGathering = async (gatheringID, email) => {
    try {
      console.log("GatheringID:", gatheringID); // Add this for debugging
      const gatheringRef = doc(firestore, "Gatherings", gatheringID);
      await updateDoc(gatheringRef, { TeamEmails: arrayUnion(email) });
      console.log("Team member added:", email);
      return { success: true };
    } catch (error) {
      console.error("Error adding team member to gathering:", error);
      return { success: false, message: error.message };
    }
  };
  

export default AddTeamMemberToGathering;
