// DeleteEvent.js
import { doc, deleteDoc } from 'firebase/firestore';
import { firestore } from '../config/FirebaseConfig'; // Adjust the path as needed


// Define the function with a parameter type
const DeleteEvent = async (eventId: string): Promise<void> => {
  console.log(eventId)
  try {
    await deleteDoc(doc(firestore, "Events", eventId));
    console.log("Event deleted with ID: ", eventId);
  } catch (error) {
    console.error("Error deleting event: ", error);
  }
};


export default DeleteEvent; 