import React, { useState, useEffect } from "react";
import "../../styles/components/event-creation-form.css"; // Import the new styles for input fields and button

// Predefined durations for the user to select from
const durations = {
  "1 hour": 60,
  "90 minutes": 90,
  "2 hours": 120,
};

const EventCreationForm = ({
  handleAddEventToSchedule,
  locations,
  startDate,
  endDate,
  initialEvent,
}) => {
  const [currentEvent, setCurrentEvent] = useState({
    eventName: "",
    description: "",
    location: "",
    startDate: "",
    startTime: "", // Default time set to 12:00
    endTime: "", // Default time set to 12:00
  });

  // Separate state for handling duration
  const [duration, setDuration] = useState("1 hour");
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (initialEvent) {
      setCurrentEvent(initialEvent);
    }
  }, [initialEvent]);

  // Calculate the endTime based on the startTime and selected duration
  const calculateEndTime = (startTime, durationInMinutes) => {
    if (!startTime) return "";
    const [hours, minutes] = startTime.split(":");
    const date = new Date();
    date.setHours(parseInt(hours));
    date.setMinutes(parseInt(minutes) + durationInMinutes);
    return date.toTimeString().slice(0, 5); // Return formatted time (HH:MM)
  };

  // Handle input changes and calculate endTime if startTime or duration changes
  const handleInputChange = (e, field) => {
    const { value } = e.target;

    if (field === "startTime") {
      const durationInMinutes = durations[duration] || 60;
      const calculatedEndTime = calculateEndTime(value, durationInMinutes);
      setCurrentEvent((prevEvent) => ({
        ...prevEvent,
        startTime: value,
        endTime: calculatedEndTime, // Automatically update endTime based on duration
      }));
    } else {
      setCurrentEvent((prevEvent) => ({
        ...prevEvent,
        [field]: value,
      }));
    }
  };

  // Handle duration change and calculate the new endTime
  const handleDurationChange = (e) => {
    const selectedDuration = e.target.value;
    setDuration(selectedDuration);

    // Update endTime if startTime is already set
    if (currentEvent.startTime) {
      const durationInMinutes = durations[selectedDuration];
      const calculatedEndTime = calculateEndTime(
        currentEvent.startTime,
        durationInMinutes
      );
      setCurrentEvent((prevEvent) => ({
        ...prevEvent,
        endTime: calculatedEndTime,
      }));
    }
  };

  // Validate form inputs
  const validateInputs = () => {
    const newErrors = {};
    if (
      currentEvent.eventName.length < 5 ||
      currentEvent.eventName.length > 50
    ) {
      newErrors.eventName = "Event Name must be between 5 and 50 characters.";
    }
    if (currentEvent.description.length > 200) {
      newErrors.description = "Description must be less than 200 characters.";
    }
    if (
      currentEvent.location.length < 2 ||
      currentEvent.location.length > 100
    ) {
      newErrors.location = "Location must be between 2 and 100 characters.";
    }
    if (!currentEvent.startDate) {
      newErrors.startDate = "Start Date must be provided.";
    }
    if (!currentEvent.startTime) {
      newErrors.startTime = "Start Time must be provided.";
    }
    if (!currentEvent.endTime) {
      newErrors.endTime = "End Time must be provided.";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  // Handle form submission
  const handleSubmit = () => {
    if (validateInputs()) {
      handleAddEventToSchedule(currentEvent);
      setCurrentEvent({
        eventName: "",
        description: "",
        location: "",
        startDate: "",
        startTime: "",
        endTime: "",
      });
      setDuration("1 hour"); // Reset duration to default
    } else {
      alert("Please correct the errors before submitting.");
    }
  };

  const generateDatesBetween = (start, end) => {
    const dates = [];
    let currentDate = new Date(start);
    const endDate = new Date(end);

    while (currentDate <= endDate) {
      dates.push(currentDate.toISOString().split("T")[0]);
      currentDate.setDate(currentDate.getDate() + 1);
    }

    return dates;
  };

  const dates = generateDatesBetween(startDate, endDate);

  return (
    <div className="event-input-container">
      {" "}
      {/* Container with padding and outline */}
      <h3>{initialEvent ? "Edit Event" : "Add a New Event"}</h3>
      <div className="form-section">
        <h4>Basic Information</h4>
        <label>Event Name</label>
        <input
          className="event-input-field"
          type="text"
          placeholder="Event Name"
          value={currentEvent.eventName}
          onChange={(e) => handleInputChange(e, "eventName")}
          required
        />
        {errors.eventName && (
          <p className="error-message">{errors.eventName}</p>
        )}
        <label>Description</label>
        <input
          className="event-input-field"
          type="text"
          placeholder="Description"
          value={currentEvent.description}
          onChange={(e) => handleInputChange(e, "description")}
        />
        {errors.description && (
          <p className="error-message">{errors.description}</p>
        )}
      </div>
      <div className="form-section">
        <h4>Time/Date</h4>
        <label>Select Date</label>
        <div className="date-buttons-container">
          {dates.map((date) => (
            <button
              key={date}
              className={`date-button ${
                currentEvent.startDate === date ? "active" : ""
              }`}
              onClick={() =>
                handleInputChange({ target: { value: date } }, "startDate")
              }
            >
              {date}
            </button>
          ))}
        </div>
        <label>Select Start Time</label>
        <input
          className="event-input-field"
          type="time"
          placeholder="Start Time"
          value={currentEvent.startTime}
          onChange={(e) => handleInputChange(e, "startTime")}
          required
        />
        {errors.startTime && (
          <p className="error-message">{errors.startTime}</p>
        )}
        <label>Select Duration (optional)</label>
        <select
          className="event-input-field"
          value={duration}
          onChange={handleDurationChange}
        >
          {Object.keys(durations).map((key) => (
            <option key={key} value={key}>
              {key}
            </option>
          ))}
        </select>
        <label>Select End Time</label>
        <input
          className="event-input-field"
          type="time"
          placeholder="End Time"
          value={currentEvent.endTime}
          onChange={(e) => handleInputChange(e, "endTime")}
          required
        />
        {errors.endTime && <p className="error-message">{errors.endTime}</p>}
      </div>
      <div className="form-section">
        <h4>Location</h4>
        {locations.map((location) => (
          <div>
            <label>Select Location</label>
            <div className="location-buttons-container">
              <button
                key={location}
                className={`location-button ${
                  currentEvent.location === location ? "active" : ""
                }`}
                onClick={() =>
                  handleInputChange({ target: { value: location } }, "location")
                }
              >
                {location}
              </button>
            </div>
          </div>
        ))}
        <label>Enter Location</label>
        <input
          className="event-input-field"
          type="text"
          placeholder="Enter a New Location"
          value={currentEvent.location}
          onChange={(e) => handleInputChange(e, "location")}
          required
        />
        {errors.location && <p className="error-message">{errors.location}</p>}
      </div>
      <button className="add-event-button" onClick={handleSubmit}>
        {initialEvent ? "Update Event" : "Add Event"}
      </button>
    </div>
  );
};

export default EventCreationForm;
