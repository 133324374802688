// ModifyEvent.js
import { doc, updateDoc } from 'firebase/firestore';
import { firestore } from '../config/FirebaseConfig'; // Adjust the path as needed
import toPascalCase from '../logic/toPascalCase';
import { EventSchema } from '../data/validationSchemas/EventSchema';

const ModifyEvent = async (eventID, eventData) => {
  console.log("modifying event ", JSON.stringify(eventData));

  // Validate eventData using the EventSchema
  const result = EventSchema.safeParse(eventData);
  if (!result.success) {
    console.error("Validation failed", result.error);
    return; // Exit if validation fails
  }

  // Convert valid eventData to PascalCase for Firestore
  let data = toPascalCase(result.data);
  console.log("pascal: " + JSON.stringify(data));

  try {
    const eventRef = doc(firestore, "Events", eventID);
    await updateDoc(eventRef, data);
    console.log("Event updated with ID: ", eventID);
  } catch (error) {
    console.error("Error updating event: ", error);
  }
};

export default ModifyEvent;
