import { collection, getDocs } from 'firebase/firestore';
import { firestore } from '../config/FirebaseConfig'; // Adjust the path as needed
import toCamelCase from '../logic/toCamelCase';
import FetchMapImage from './FetchMapImage'; // Import the function
import { Gathering } from '../data/classes/Gathering'; // Ensure correct import

const FetchAllGatherings = async (): Promise<Gathering[]> => {
  try {
    const gatheringsRef = collection(firestore, "Gatherings");
    const querySnapshot = await getDocs(gatheringsRef);
    
    const gatherings: Gathering[] = await Promise.all(
      querySnapshot.docs.map(async (docSnap) => {
        const gatheringData = toCamelCase({ id: docSnap.id, ...docSnap.data() }) as Gathering;
        
        const mapImageUrl = await FetchMapImage(docSnap.id);
        if (mapImageUrl) {
          gatheringData.mapImage = mapImageUrl;
        }

        return gatheringData;
      })
    );

    return gatherings;
  } catch (error) {
    console.error("Error fetching gatherings: ", error);
    return [];
  }
};

export default FetchAllGatherings;
