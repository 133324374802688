import { doc, updateDoc } from "firebase/firestore";
import { firestore } from "../config/FirebaseConfig";
import toPascalCase from "../logic/toPascalCase";
import { Gathering } from "../data/classes/Gathering";
import { GatheringSchema } from "../data/validationSchemas/GatheringSchema";
import checkAvailability from "./CheckAvailability";

const ModifyGathering = async (
  gatheringID: string,
  summaryData: Partial<Gathering>
): Promise<void | string> => {
  console.log("Modifying gathering:", gatheringID);
  console.log("Data received:", summaryData);

  // Validate the provided data
  const result = GatheringSchema.partial().safeParse(summaryData);

  if (!result.success) {
    throw new Error(
      `Validation failed: ${result.error.errors
        .map((e) => e.message)
        .join(", ")}`
    );
  }

  // Check availability only for specific fields
  if (summaryData.shortcode || summaryData.gatheringName) {
    const { isShortcodeAvailable, isGatheringNameAvailable } =
      await checkAvailability(
        summaryData.shortcode,
        summaryData.gatheringName,
        summaryData.startDate,
        summaryData.endDate,
        gatheringID
      );

    if (!isShortcodeAvailable || !isGatheringNameAvailable) {
      const errorMessage = [];
      if (!isShortcodeAvailable)
        errorMessage.push("Shortcode is already in use.");
      if (!isGatheringNameAvailable)
        errorMessage.push("Gathering name is already in use.");
      return errorMessage.join(" ");
    }
  }

  // Transform data to PascalCase
  const data = toPascalCase(result.data);

  console.log("Data to be updated:", data);

  // Prevent attempting an empty update
  if (Object.keys(data).length === 0) {
    throw new Error("No valid fields to update. Data is empty.");
  }

  try {
    const gatheringRef = doc(firestore, "Gatherings", gatheringID);
    await updateDoc(gatheringRef, data);
    console.log(`Successfully updated gathering: ${gatheringID}`);
  } catch (error) {
    console.error("Error updating gathering document:", error);
    throw new Error("Error updating gathering");
  }
};

export default ModifyGathering;
