import { initializeApp } from "firebase/app";
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";

// Your Firebase config
const firebaseConfig = {
  apiKey: "AIzaSyBdF3EMeUS9vqPfyYaoijoIfXIei1yt5bs",
  authDomain: "owldata-52131.firebaseapp.com",
  projectId: "owldata-52131",
  storageBucket: "owldata-52131.appspot.com",
  messagingSenderId: "1023761907481",
  appId: "1:1023761907481:web:df13c4e1258e2ae3958f88",
  measurementId: "G-EMNNJ0P4EW",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const functions = getFunctions(app);

const analytics = getAnalytics(app);
const firestore = getFirestore(app);
const auth = getAuth(app);


// Function to get site key for App Check
const getSiteKey = async () => {
  const getAppCheckSiteKey = httpsCallable(functions, 'getAppCheckSiteKey');
  const result = await getAppCheckSiteKey();
  return result.data.siteKey; // Return the site key
};

// Initialize App Check after fetching site key
const initializeAppCheckWithSiteKey = async () => {
  try {
    const siteKey = await getSiteKey(); // Fetch the site key asynchronously
    const appCheck = initializeAppCheck(app, {
      provider: new ReCaptchaV3Provider(siteKey),
      isTokenAutoRefreshEnabled: true, // Automatically refresh App Check token
    });
    //console.log("App Check initialized with siteKey:", siteKey);
  } catch (error) {
    console.error("Error initializing App Check:", error);
  }
};

// Call the function to initialize App Check
initializeAppCheckWithSiteKey();

export { auth, firestore, functions };
