import { collection, query, where, getDocs, QueryDocumentSnapshot } from 'firebase/firestore';
import { firestore } from '../config/FirebaseConfig';
import toCamelCase from '../logic/toCamelCase';

interface Gathering {
    id: string;
    gatheringName: string;
    startDate: string;
    endDate: string;
    location: string;
    [key: string]: any;  // Allow for additional dynamic properties
}

const FetchParticipantGatherings = async (gatheringReferenceIDs: string[]): Promise<Gathering[]> => {
    console.log("attempting to fetch participant gatherings")
    // Return early if the list of IDs is empty to avoid an invalid query
    if (gatheringReferenceIDs.length === 0) {
        console.log("No GatheringReferenceIDs provided, returning empty array.");
        return [];
    }

    const gatheringsRef = collection(firestore, "Gatherings");
    const q = query(gatheringsRef, where("ReferenceID", "in", gatheringReferenceIDs));


    try {
        const querySnapshot = await getDocs(q);
        const gatherings: Gathering[] = querySnapshot.docs.map((doc: QueryDocumentSnapshot): Gathering => {
            // Convert Firestore document to camelCase including the id
            const data = doc.data();
            const dataWithId = { id: doc.id, ...data };
            return toCamelCase(dataWithId) as Gathering;
        });
        console.log(gatherings)
        return gatherings;
    } catch (error) {
        console.error("Error fetching gatherings: ", error);
        throw new Error("Failed to fetch gatherings"); // Propagate error
    }
};

export default FetchParticipantGatherings;
