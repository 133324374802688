// src/components/LoadingScreen/LoadingScreen.js

import React from "react";
import "../../styles/components/loading-screen.css";

const LoadingScreen = () => {
  return (
    <div className="loading-screen">
      <div className="loading-spinner"></div>
      <p>Loading...</p>
    </div>
  );
};

export default LoadingScreen;
