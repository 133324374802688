import { collection, doc, setDoc } from "firebase/firestore";
import { firestore, auth } from "../config/FirebaseConfig";
import { AnnouncementSchema } from "../data/validationSchemas/AnnouncementSchema";
import toPascalCase from "../logic/toPascalCase";
import { Announcement } from "../data/classes/Announcement";

const CreateAnnouncement = async (input: Announcement): Promise<string | null> => {
  if (!auth.currentUser) {
    console.error("No authenticated user found.");
    return null;
  }

  try {
    const newDocRef = doc(collection(firestore, "Announcements"));
    const customId = newDocRef.id;

    const result = AnnouncementSchema.safeParse({
      ...input,
      id: customId,
      createdAt: new Date(),
    });
    if (!result.success) {
      console.error("Validation failed", result.error);
      return null;
    }

    const firestoreData = toPascalCase(result.data);
    await setDoc(newDocRef, firestoreData);

    console.log("Announcement created with ID:", customId);
    return customId;
  } catch (error) {
    console.error("Error creating announcement:", error);
    return null;
  }
};

export default CreateAnnouncement;
