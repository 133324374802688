import React, { useState } from "react";
import EventCreationForm from "../EventCreationForm/EventCreationForm";
import ScheduleList from "../ScheduleList/ScheduleList";
import "../../styles/components/gathering-customization-form-component.css"; // Import updated CSS for full-height sidebar

const ScheduleTab = ({
  schedule,
  handleAddEventToSchedule,
  handleDeleteEvent,
  startDate,
  endDate,
}) => {
  const [isFormExpanded, setIsFormExpanded] = useState(false); // State to track form expansion

  const uniqueLocations = Array.from(
    new Set(schedule.map((event) => event.location))
  );

  const toggleForm = () => {
    setIsFormExpanded(!isFormExpanded); // Toggle the form expansion state
  };

  return (
    <div className="input-container">
      <h3 className="tab-title">Event Schedule</h3>
      <div className="event-creation-toggle" onClick={toggleForm}>
        {isFormExpanded ? "Hide Event Creation Form" : "Add a New Event"}
      </div>
      {isFormExpanded && (
        <EventCreationForm
          handleAddEventToSchedule={handleAddEventToSchedule}
          locations={uniqueLocations}
          startDate={startDate}
          endDate={endDate}
        />
      )}
      <ScheduleList schedule={schedule} handleDeleteEvent={handleDeleteEvent} />
    </div>
  );
};

export default ScheduleTab;
