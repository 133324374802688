function daysUntil(dateArgument) {
    const currentDate = new Date();
    const targetDate = new Date(dateArgument);
  
    // Set the time of both dates to midnight to ignore time differences within a day
    currentDate.setHours(0, 0, 0, 0);
    targetDate.setHours(0, 0, 0, 0);
  
    const timeDifference = targetDate - currentDate;
    const daysDifference = timeDifference / (1000 * 60 * 60 * 24); // Convert milliseconds to days
  
    if (daysDifference < 0) {
      return "Already occurred";
    } else {
      return Math.round(daysDifference) + " days";
    }
  }

  export default daysUntil;