import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import ModifyGathering from "../../api/ModifyGathering";
import CreateEvent from "../../api/CreateEvent";
import FetchGathering from "../../api/FetchGathering";
import FetchEvents from "../../api/FetchEvents";
import DeleteEvent from "../../api/DeleteEvent";
import toCamelCase from "../../logic/toCamelCase";
import "../../styles/components/gathering-customization-form-component.css"; // Import updated CSS for full-height sidebar

import Papa from "papaparse";
import * as XLSX from "xlsx";
import Sidebar from "../Sidebar/Sidebar";
import FetchMapImage from "../../api/FetchMapImage";
import FetchAnnouncements from "../../api/FetchAnnouncements";
import AnnouncementsTab from "../Tabs/AnnouncmentsTab";
import SummaryTab from "../Tabs/SummaryTab";
import MapTab from "../Tabs/MapTab";
import ScheduleTab from "../Tabs/ScheduleTab";
import LogoAndColors from "../Tabs/LogoAndColorsTab";
import ParticipantTab from "../Tabs/ParticipantTab";
import uploadImage from "../../api/UploadImage";
import FetchLogoImage from "../../api/FetchLogoImage";
import { GatheringSchema } from "../../data/validationSchemas/GatheringSchema";
import removeUndefinedFields from "../../logic/removeUndefinedFields";
import TeamTab from "../Tabs/TeamTab";
import AddTeamMemberToGathering from "../../api/AddTeamMemberToGathering";
import RemoveTeamMemberFromGathering from "../../api/RemoveTeamMemberFromGathering";
import FetchParticipant from "../../api/FetchParticipant";
import DeleteExistingImage from "../../api/DeleteExistingImage";

const GatheringCustomizationForm = () => {
  const { gatheringID } = useParams();
  const [activeTab, setActiveTab] = useState("Summary");
  const [mapFile, setMapFile] = useState(null);
  const [logoFile, setLogoFile] = useState(null);
  const [announcements, setAnnouncements] = useState([]);
  const [teamEmails, setTeamEmails] = useState([]); // State for team members' emails
  const [gatheringVendorID, setGatheringVendorID] = useState(null);
  const [gatheringReferenceID, setGatheringReferenceID] = useState(null);
  const [vendorIDs, setVendorIDs] = useState([]);
  const [artistIDs, setArtistIDs] = useState([]); // State for Artist IDs
  const [vendors, setVendors] = useState([]); // Fetched Vendors
  const [artists, setArtists] = useState([]); // Fetched Artists
  const [formData, setFormData] = useState({
    summary: {
      gatheringName: "",
      description: "",
      location: "",
      shortcode: "",
      startDate: "",
      endDate: "",
      hostName: "",
      hostPhoneNum: "",
      medicPhoneNum: "",
      securityPhoneNum: "",
      hostEmail: "",
      primaryColor: "",
      secondaryColor: "",
    },
    map: {
      mapImage: "",
    },
    logo: {
      logoImage: "",
    },
    schedule: [],
  });

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      const gatheringData = await FetchGathering(gatheringID);
      const eventsData = await FetchEvents(gatheringID);
      const mapImageUrl = await FetchMapImage(gatheringID);
      const logoImageUrl = await FetchLogoImage(gatheringID);

      const fetchedAnnouncements = await FetchAnnouncements(gatheringID);

      if (mapImageUrl) {
        setFormData((prevData) => ({
          ...prevData,
          map: { ...prevData.map, mapImage: mapImageUrl },
        }));
      }
      if (logoImageUrl) {
        setFormData((prevData) => ({
          ...prevData,
          logo: { ...prevData.logo, logoImage: logoImageUrl },
        }));
      }
      if (gatheringData) {
        setFormData((prevFormData) => ({
          ...prevFormData,
          summary: { ...prevFormData.summary, ...gatheringData },
        }));
        setVendorIDs(gatheringData.vendorIDs || []);
        setArtistIDs(gatheringData.artistIDs || []);
        setGatheringReferenceID(gatheringData.referenceID || null);

        // Check if teamEmails is an object and convert it to an array
        if (
          typeof gatheringData.teamEmails === "object" &&
          !Array.isArray(gatheringData.teamEmails)
        ) {
          gatheringData.teamEmails = Object.values(gatheringData.teamEmails); // Convert object to array
        }

        setTeamEmails(
          Array.isArray(gatheringData.teamEmails)
            ? gatheringData.teamEmails
            : []
        );

      }

      if (eventsData.length > 0) {
        const uniqueEvents = Array.from(
          new Set(eventsData.map((event) => event.id))
        ).map((id) => eventsData.find((event) => event.id === id));
        setFormData((prevFormData) => ({
          ...prevFormData,
          schedule: uniqueEvents,
        }));
      }
      if (fetchedAnnouncements) {
        setAnnouncements(fetchedAnnouncements.map((ann) => toCamelCase(ann)));
      }
    };

    if (gatheringID) {
      fetchData();
    }
  }, [gatheringID]);

  // useEffect(() => {
  //   console.log("Updated teamEmails state:", teamEmails); // Logs when the state actually changes
  // }, [teamEmails]);

  useEffect(() => {
    const fetchParticipants = async () => {
      try {
        // Ensure vendorIDs and artistIDs are normalized as arrays
        const safeArray = (data) => (Array.isArray(data) ? data : Object.values(data || {}));
  
        const normalizedVendorIDs = safeArray(vendorIDs); // From state or props
        const normalizedArtistIDs = safeArray(artistIDs); // From state or props
  
        // Fetch Vendors and Artists separately
        const vendorPromises = normalizedVendorIDs.map((id) =>
          FetchParticipant(id, "Vendor", true) // Fetch by reference ID
        );
        const artistPromises = normalizedArtistIDs.map((id) =>
          FetchParticipant(id, "Artist", true) // Fetch by reference ID
        );
  
        // Wait for all promises to resolve
        const fetchedVendors = await Promise.all(vendorPromises);
        const fetchedArtists = await Promise.all(artistPromises);
  
        // Set state for vendors and artists, filtering out null responses
        setVendors(fetchedVendors.filter((vendor) => vendor !== null));
        setArtists(fetchedArtists.filter((artist) => artist !== null));
      } catch (error) {
        console.error("Error fetching participants:", error);
        alert("Failed to fetch participants. Please try again later.");
      }
    };
  
    fetchParticipants(); // Call the fetch function
  }, [vendorIDs, artistIDs]); // Watch for changes in vendorIDs or artistIDs
  
    // Callback to update vendor participants
    const updateVendors = (updatedVendors) => {
      setVendors(updatedVendors);
    };
  
    // Callback to update artist participants
    const updateArtists = (updatedArtists) => {
      setArtists(updatedArtists);
    };
  

  const handleInputChange = (e, section, field) => {
    const { value } = e.target;
    const camelCaseField = field.charAt(0).toLowerCase() + field.slice(1);

    setFormData((prevFormData) => ({
      ...prevFormData,
      [section]: {
        ...prevFormData[section],
        [camelCaseField]: value,
      },
    }));
  };

  const handleMapChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setMapFile(file); // Store the file
    }
  };

  const handleLogoChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setLogoFile(file); // Store the file
    }
  };

  const handleAddEventToSchedule = async (newEvent) => {
    const eventID = await CreateEvent(newEvent, gatheringID);
    if (eventID) {
      const updatedEvent = { ...newEvent, id: eventID };
      setFormData((prevFormData) => ({
        ...prevFormData,
        schedule: [...prevFormData.schedule, updatedEvent],
      }));
    }
  };

  // const handleCSVUpload = async (event) => {
  //   const file = event.target.files[0];
  //   const fileExtension = file.name.split(".").pop();

  //   if (fileExtension === "csv") {
  //     // Parse CSV data
  //     Papa.parse(file, {
  //       header: true, // Assume the first row contains headers
  //       complete: (results) => {
  //         addParsedEventsToSchedule(results.data);
  //       },
  //     });
  //   } else if (["xls", "xlsx"].includes(fileExtension)) {
  //     // Parse Excel data
  //     const workbook = XLSX.read(await file.arrayBuffer(), { type: "array" });
  //     const firstSheetName = workbook.SheetNames[0];
  //     const worksheet = XLSX.utils.sheet_to_json(
  //       workbook.Sheets[firstSheetName],
  //       {
  //         header: 1, // Use first row as header
  //       }
  //     );
  //     const [headers, ...data] = worksheet;
  //     const events = data.map((row) => {
  //       const event = {};
  //       headers.forEach((header, index) => {
  //         event[header] = row[index];
  //       });
  //       return event;
  //     });

  //     addParsedEventsToSchedule(events);
  //   }
  // };

  // const addParsedEventsToSchedule = (parsedData) => {
  //   console.log("PARSED DATA: " + JSON.stringify(parsedData));
  //   const newSchedule = parsedData.map((event) => ({
  //     eventName: event.EventName || "",
  //     description: event.Description || "",
  //     location: event.Location || "",
  //     startDate: event.StartDate || "",
  //     startTime: event.StartTime || "",
  //     endTime: event.EndTime || "",
  //     isNew: true, // Mark as new
  //   }));

  //   setFormData((prevFormData) => ({
  //     ...prevFormData,
  //     schedule: [...prevFormData.schedule, ...newSchedule], // Add to schedule
  //   }));
  // };

  const addTeamMember = async (email) => {
    try {
      // Call the API function to add the team member to the Gathering in Firestore
      const result = await AddTeamMemberToGathering(gatheringID, email);

      if (result.success) {
        // If successful, update the local state to reflect the new team member
        setTeamEmails((prevEmails) => [...prevEmails, email]);
      } else {
        console.error("Error adding team member:", result.message);
        alert(`Failed to add team member: ${result.message}`);
      }
    } catch (error) {
      console.error("Error adding team member:", error);
      alert("An error occurred while adding the team member.");
    }
  };

  const handleRemoveTeamMember = async (email) => {
    try {
      const result = await RemoveTeamMemberFromGathering(gatheringID, email);
      if (result.success) {
        setTeamEmails((prevEmails) =>
          prevEmails.filter((existingEmail) => existingEmail !== email)
        );

        alert(`${email} removed successfully.`);
        // Optionally, you can call a function here to refresh the state after removing the team member
      } else {
        alert(`Failed to remove ${email}.`);
      }
    } catch (error) {
      console.error("Error adding team member:", error);
      alert("An error occurred while adding the team member.");
    }
  };

  const handleSubmit = async () => {
    if (!isSubmitting) {
      setIsSubmitting(true);
      try {
        let mapUrl = formData.map.mapImage;
        let logoUrl = formData.logo.logoImage;
  
        // If a new map file is provided, delete existing images and upload the new one
        if (mapFile) {
          await DeleteExistingImage(gatheringID, "gathering-map");
          mapUrl = await uploadImage(mapFile, gatheringID, "gathering-map");
          if (mapUrl) {
            setFormData((prevData) => ({
              ...prevData,
              map: { ...prevData.map, mapImage: mapUrl },
            }));
          }
        }
  
        // If a new logo file is provided, delete existing images and upload the new one
        if (logoFile) {
          await DeleteExistingImage(gatheringID, "gathering-logo");
          logoUrl = await uploadImage(logoFile, gatheringID, "gathering-logo");
          if (logoUrl) {
            setFormData((prevData) => ({
              ...prevData,
              logo: { ...prevData.logo, logoImage: logoUrl },
            }));
          }
        }
  
        // Ensure teamEmails is handled correctly
        const updatedData = {
          ...formData.summary,
          vendors: Array.isArray(formData.summary.vendors)
            ? formData.summary.vendors
            : Object.values(formData.summary.vendors || []),
          teamEmails: Array.isArray(teamEmails) ? teamEmails : [],
          hostEmail: formData.summary.hostEmail || undefined,
          mapImage: mapUrl,
          logoImage: logoUrl,
        };
  
        const cleanedData = removeUndefinedFields(updatedData);
  
        // Validate using Zod before submitting
        const result = GatheringSchema.safeParse(cleanedData);
        if (!result.success) {
          const newErrors = result.error.errors.reduce((acc, err) => {
            acc[err.path[0]] = err.message;
            return acc;
          }, {});
          setErrors(newErrors);
          console.error("Validation failed", result.error);
          setIsSubmitting(false);
          return; // Stop submission if validation fails
        }
  
        // Submit the cleaned and validated data
        const modifyResult = await ModifyGathering(gatheringID, cleanedData);
  
        if (typeof modifyResult === "string") {
          setErrors({ apiError: modifyResult });
          setIsSubmitting(false);
          return;
        }
  
        setIsSubmitting(false);
        setErrors({});
      } catch (error) {
        console.error("Error updating gathering:", error);
        setIsSubmitting(false);
      }
    }
  };
  

  const handleDeleteEvent = async (eventId) => {
    await DeleteEvent(eventId);
    setFormData((prevFormData) => ({
      ...prevFormData,
      schedule: prevFormData.schedule.filter((event) => event.id !== eventId),
    }));
  };

  // Render functions for Summary, Map, and Schedule fields go here, similar to GatheringCreationForm

  const handleTabClick = (tab) => {
    setActiveTab(tab); // Update active tab
  };

  const renderFormFields = () => {
    switch (activeTab) {
      case "Summary":
        return (
          <SummaryTab
            formData={formData}
            handleInputChange={handleInputChange}
            errors={errors}
          />
        );
      case "LogoAndColors":
        return (
          <LogoAndColors
            logoUrl={formData.logo.logoImage}
            handleLogoChange={handleLogoChange}
            primaryColor={formData.summary.primaryColor}
            secondaryColor={formData.summary.secondaryColor}
          />
        );
      case "Map":
        return (
          <MapTab
            mapImage={formData.map.mapImage}
            handleMapChange={handleMapChange}
          />
        );
      case "Schedule":
        return (
          <ScheduleTab
            schedule={formData.schedule}
            handleAddEventToSchedule={handleAddEventToSchedule}
            handleDeleteEvent={handleDeleteEvent}
            startDate={formData.summary.startDate}
            endDate={formData.summary.endDate}
          />
        );
      case "Announcements":
        return (
          <AnnouncementsTab
            announcements={announcements}
            gatheringID={gatheringID}
          />
        );
      case "Team":
        return (
          <TeamTab
            hostEmail={formData.summary.hostEmail}
            teamEmails={teamEmails}
            addTeamMember={addTeamMember}
            removeTeamMember={handleRemoveTeamMember}
          />
        );
        case "Artists":
          return (
            <ParticipantTab
              participantType="Artist"
              participants={artists} // Pass fetched artists
              gatheringID={gatheringID}
              gatheringReferenceID={gatheringReferenceID}
              updateParticipants={updateArtists} // Callback to update artists in parent state
            />
          );
        case "Vendors":
          return (
            <ParticipantTab
              participantType="Vendor"
              participants={vendors} // Pass fetched vendors
              gatheringID={gatheringID}
              gatheringReferenceID={gatheringReferenceID}
              updateParticipants={updateVendors} // Callback to update vendors in parent state
            />
          );
        
      default:
        return <div>Select a tab</div>;
    }
  };

  return (
    <div>
      <div className="customization-container">
        <Sidebar activeTab={activeTab} onTabClick={handleTabClick} />
        <div className="content">
          {renderFormFields()}
          <button
            className="save-button"
            onClick={handleSubmit}
            disabled={isSubmitting}
          >
            Save Changes
          </button>
        </div>
      </div>
    </div>
  );
};

export default GatheringCustomizationForm;
