// FetchGatherings.js
import { doc, getDoc } from 'firebase/firestore';
import { firestore } from '../config/FirebaseConfig'; // Adjust the path as needed
import toCamelCase from '../logic/toCamelCase';
import FetchMapImage from './FetchMapImage'; // Import the new function
import { Gathering } from '../data/classes/Gathering'; // Ensure correct import

const FetchGathering = async (gatheringID: string): Promise<Gathering | null> => {
  try {
    const docRef = doc(firestore, "Gatherings", gatheringID);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      const gatheringData = toCamelCase({ id: docSnap.id, ...docSnap.data() }) as Gathering;

      console.log(JSON.stringify(gatheringData))
      // Fetch the map image URL and add it to the gathering data
      const mapImageUrl = await FetchMapImage(gatheringID);
      if (mapImageUrl) {
        gatheringData.mapImage = mapImageUrl; // Now this line is valid
      }

      return gatheringData;
    } else {
      console.log("No such gathering!");
      return null;
    }
  } catch (error) {
    console.error("Error fetching gathering: ", error);
    return null;
  }
};

export default FetchGathering;

