import { collection, doc, setDoc, getDocs, query, where } from "firebase/firestore";
import { auth, firestore } from "../config/FirebaseConfig";
import { Participant } from "../data/classes/Participant";
import { ParticipantSchema } from "../data/validationSchemas/ParticipantSchema";
import toPascalCase from "../logic/toPascalCase";

type ParticipantFormData = {
  name: string;
  email: string;
  website?: string;
  description?: string;
  userID: string;
  gatheringReferenceID?: string; // Optional, for linking to a specific gathering
};

const CreateParticipant = async (
  formData: ParticipantFormData,
  type: "Vendor" | "Artist"
): Promise<string | null> => {
  const { name, email, website, description, userID, gatheringReferenceID } = formData;

  if (!auth.currentUser) {
    console.error("No authenticated user found.");
    return null;
  }

  try {
    // Initialize the array of gatheringReferenceIDs if a gatheringReferenceID was provided
    let gatheringReferenceIDs = gatheringReferenceID ? [gatheringReferenceID] : [];

    // If a gatheringReferenceID was provided, verify it exists in the Gatherings collection
    if (gatheringReferenceID) {
      const gatheringsRef = collection(firestore, "Gatherings");
      const q = query(gatheringsRef, where("GatheringReferenceID", "==", gatheringReferenceID));
      const querySnapshot = await getDocs(q);

      if (querySnapshot.empty) {
        console.error(`No Gathering found with the provided reference ID: ${gatheringReferenceID}`);
        alert("No valid Gathering found with the provided reference ID. Please check and try again.");
        return null;
      }

      // Populate gatheringReferenceIDs based on valid Gathering documents found
      gatheringReferenceIDs = querySnapshot.docs.map((doc) => doc.data().GatheringReferenceID);
    }

    // Determine the participant's collection and Firestore document reference ID
    const participantCollection = type === "Vendor" ? "Vendors" : "Artists";
    const participantRef = doc(collection(firestore, participantCollection));
    const referenceID = participantRef.id; // Using Firestore document ID as the reference ID

    const participant = new Participant(
      referenceID,              // Document ID and reference ID
      name,
      userID,
      email,
      referenceID,              // Assign referenceID as a generic ID
      type,
      website,
      description,
      gatheringReferenceIDs     // Use the updated gatheringReferenceIDs array
    );
    
    // Validate the participant data using the unified schema
    const result = ParticipantSchema.safeParse(participant);
    if (!result.success) {
      console.error("Validation failed:", result.error);
      return null;
    }

    // Convert the participant data to Firestore format and save it
    const firestoreData = toPascalCase(result.data);
    await setDoc(participantRef, firestoreData);

    console.log(`${type} document created with ID:`, referenceID);
    return referenceID;
  } catch (error) {
    console.error(`Error creating ${type} document:`, error);
    return null;
  }
};

export default CreateParticipant;
