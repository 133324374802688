import React, { useState } from "react";
import "../../styles/components/gathering-customization-form-component.css"; // Import updated CSS for full-height sidebar

const SummaryTab = ({ formData, handleInputChange, errors }) => {
  const {
    gatheringName,
    description,
    location,
    shortcode,
    startDate,
    endDate,
    hostName,
    hostPhoneNum,
    medicPhoneNum,
    securityPhoneNum,
    hostEmail,
  } = formData.summary;

  const [isEditable, setIsEditable] = useState(false); // State to manage edit mode

  const toggleEditMode = () => {
    setIsEditable(!isEditable); // Toggle edit mode
  };

  return (
    <div className="input-container">
      <h3 className="tab-title">Summary</h3>
      <button className="edit-mode-button" onClick={toggleEditMode}>
        {isEditable ? "Disable Edit Mode" : "Enable Edit Mode"}
      </button>

      {errors.apiError && <p className="error-message">{errors.apiError}</p>} {/* Display API error */}

      
      <p className="input-label">Gathering Name:</p>
      <input
        className="input-field"
        type="text"
        placeholder="Gathering Name"
        value={gatheringName}
        onChange={(e) => handleInputChange(e, "summary", "GatheringName")}
        disabled={!isEditable}
      />
      {errors.gatheringName && (
        <p className="error-message">{errors.gatheringName}</p>
      )}
      
      <p className="input-label">Gathering Description:</p>
      <input
        className="input-field"
        type="text"
        placeholder="Description"
        value={description}
        onChange={(e) => handleInputChange(e, "summary", "Description")}
        disabled={!isEditable}
      />
      {errors.description && (
        <p className="error-message">{errors.description}</p>
      )}
      
      <p className="input-label">Gathering Location:</p>
      <input
        className="input-field"
        type="text"
        placeholder="Location"
        value={location}
        onChange={(e) => handleInputChange(e, "summary", "Location")}
        disabled={!isEditable}
      />
      {errors.location && (
        <p className="error-message">{errors.location}</p>
      )}
      
      <p className="input-label">Gathering Shortcode:</p>
      <input
        className="input-field"
        type="text"
        placeholder="Shortcode"
        value={shortcode}
        onChange={(e) => handleInputChange(e, "summary", "Shortcode")}
        disabled={!isEditable}
      />
      {errors.shortcode && (
        <p className="error-message">{errors.shortcode}</p>
      )}
      
      <p className="input-label">Start Date:</p>
      <input
        className="input-field"
        type="date"
        placeholder="Start Date"
        value={startDate}
        onChange={(e) => handleInputChange(e, "summary", "StartDate")}
        disabled={!isEditable}
      />
      {errors.startDate && (
        <p className="error-message">{errors.startDate}</p>
      )}
      
      <p className="input-label">End Date:</p>
      <input
        className="input-field"
        type="date"
        placeholder="End Date"
        value={endDate}
        onChange={(e) => handleInputChange(e, "summary", "EndDate")}
        disabled={!isEditable}
      />
      {errors.endDate && (
        <p className="error-message">{errors.endDate}</p>
      )}
      
      <p className="input-label">Host Name:</p>
      <input
        className="input-field"
        type="text"
        placeholder="Host Name"
        value={hostName}
        onChange={(e) => handleInputChange(e, "summary", "HostName")}
        disabled={!isEditable}
      />
      {errors.hostName && (
        <p className="error-message">{errors.hostName}</p>
      )}
      
      <p className="input-label">Host Phone Number:</p>
      <input
        className="input-field"
        type="text"
        placeholder="Host Phone Number"
        value={hostPhoneNum}
        onChange={(e) => handleInputChange(e, "summary", "HostPhoneNum")}
        disabled={!isEditable}
      />
      {errors.hostPhoneNum && (
        <p className="error-message">{errors.hostPhoneNum}</p>
      )}
      
      <p className="input-label">Medic/First Aid Phone Number:</p>
      <input
        className="input-field"
        type="text"
        placeholder="Medic/First Aid Phone Number"
        value={medicPhoneNum}
        onChange={(e) => handleInputChange(e, "summary", "MedicPhoneNum")}
        disabled={!isEditable}
      />
      {errors.medicPhoneNum && (
        <p className="error-message">{errors.medicPhoneNum}</p>
      )}
      
      <p className="input-label">Security Phone Number:</p>
      <input
        className="input-field"
        type="text"
        placeholder="Security Phone Number"
        value={securityPhoneNum}
        onChange={(e) => handleInputChange(e, "summary", "SecurityPhoneNum")}
        disabled={!isEditable}
      />
      {errors.securityPhoneNum && (
        <p className="error-message">{errors.securityPhoneNum}</p>
      )}
      
      <p className="input-label">Host Email:</p>
      <input
        className="input-field"
        type="email"
        placeholder="Host Email"
        value={hostEmail}
        onChange={(e) => handleInputChange(e, "summary", "HostEmail")}
        disabled={!isEditable}
      />
      {errors.hostEmail && (
        <p className="error-message">{errors.hostEmail}</p>
      )}
    </div>
  );
};

export default SummaryTab;
