import React from "react";
import itiniriLogo from "../../images/itiniri_logo_dark.png";
import { useNavigate, Link, useLocation } from "react-router-dom";
import Dropdown from "../Dropdown/Dropdown";

const Header = ({ title, user, signOutUser, isAdmin }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleSignOut = async () => {
    await signOutUser();
    navigate("/");
  };

  const dropdownOptions = [
    { label: "Account Settings", link: "/account" },
    { label: "Sign Out", onClick: handleSignOut },
  ];

  return (
    <header style={styles.headerStyle}>
      <div style={styles.logoContainerStyle} onClick={() => navigate("/")}>
        <img src={itiniriLogo} alt="Site Logo" style={styles.logoStyle} />
      </div>
      <nav>
        {!user ? (
          <></>
        ) : (
          <>
            {isAdmin && (
              <Link to="/admin">
                <button style={styles.buttonStyle}>Go to Admin View</button>
              </Link>
            )}
            {location.pathname !== "/" && (
              <button style={styles.buttonStyle}>
                <Link style={styles.linkStyle} to="/">
                  Return to Home
                </Link>
              </button>
            )}
            <Dropdown options={dropdownOptions} />
          </>
        )}
      </nav>
    </header>
  );
};

const styles = {
  headerStyle: {
    backgroundColor: "#C5EAF7",
    color: "#fff",
    padding: "10px 20px",
    minHeight: "70px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderBottom: "1px solid #1D4250",
  },
  logoStyle: {
    width: "130px",
    margin: "0 10px",
    cursor: "pointer",
  },
  buttonStyle: {
    padding: "10px 20px",
    margin: "10px",
    textDecoration: "none",
    border: "none",
    backgroundColor: "#2183a6",
    color: "white",
    cursor: "pointer",
    borderRadius: "5px",
  },
  linkStyle: {
    textDecoration: "none",
    color: "inherit",
    padding: "10px 20px",
    margin: "10px",
  },
};

export default Header;
