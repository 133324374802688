// FetchAnnouncements.js
import { collection, query, where, getDocs } from 'firebase/firestore';
import { firestore } from '../config/FirebaseConfig'; // Adjust the path as needed
import { Announcement } from '../data/classes/Announcement';
import toCamelCase from '../logic/toCamelCase';

const FetchAnnouncements = async (gatheringID: string): Promise<Announcement[]> => {
  try {
    const q = query(collection(firestore, "Announcements"), where("GatheringID", "==", gatheringID));
    const querySnapshot = await getDocs(q);
    const announcements = querySnapshot.docs.map(doc => toCamelCase({ id: doc.id, ...doc.data() }) as Announcement);
    
    return announcements;
  } catch (error) {
    console.error("Error fetching announcements:", error);
    return [];
  }
};
export default FetchAnnouncements;