import QRCode from "qrcode";

/**
 * Generate a dynamic link.
 *
 * @param {string} gatheringId - The ID of the gathering.
 * @param {string} shortcode - The shortcode for the gathering.
 * @returns {string} - The dynamic link URL.
 */
export const generateDynamicLink = (gatheringId, shortcode) => {
  return `https://itiniri.app/join/${gatheringId}?shortcode=${shortcode}`;
};

/**
 * Generate a QR code as a base64 string.
 *
 * @param {string} url - The URL to encode in the QR code.
 * @returns {Promise<string>} - The base64-encoded QR code.
 */
export const generateQRCode = async (url) => {
  try {
    return await QRCode.toDataURL(url, {
      errorCorrectionLevel: "L",
      scale: 4,
    });
  } catch (error) {
    console.error("Error generating QR code:", error);
    throw new Error("Unable to generate QR code");
  }
};
