import { getFunctions, httpsCallable } from "firebase/functions";

const functions = getFunctions();

const AddVendorToGathering = async (vendorID, gatheringID) => {
  try {
    // Create the callable function
    const addVendorToGathering = httpsCallable(functions, 'addVendorFromGathering');
    
    // Call the function and pass the necessary data
    const result = await addVendorToGathering({
      vendorId: vendorID,
      gatheringId: gatheringID,
    });
    
    return result.data; // Assuming the Firebase function returns a success object
  } catch (error) {
    console.error('Error adding vendor to gathering:', error);
    return false;
  }
};

export default AddVendorToGathering;
