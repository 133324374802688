import React, { useState, useEffect, useCallback } from 'react';
import Modal from '../Modal/Modal';
import EventCreationForm from '../EventCreationForm/EventCreationForm';
import ModifyEvent from '../../api/ModifyEvent';
import '../../styles/components/schedule-list.css'; // Assume similar CSS styling rules
import { formatTimeTo12Hour } from '../../logic/formatTimeTo12Hour';

const ScheduleList = ({ schedule, handleDeleteEvent, startDate, endDate }) => {
  const [eventsData, setEventsData] = useState([]);
  const [locationFilter, setLocationFilter] = useState('All');
  const [locations, setLocations] = useState(['All']);
  const [collapsedDays, setCollapsedDays] = useState({});
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);

  const groupEventsByDay = useCallback((events) => {
    const eventsByDay = events.reduce((acc, event) => {
      if (locationFilter === 'All' || event.location === locationFilter) {
        const eventDate = new Date(event.startDate + 'T' + event.startTime + ':00');
        const dayKey = eventDate.toISOString().split('T')[0];
        const dayDisplay = eventDate.toLocaleDateString('en-US', { weekday: 'long', month: 'long', day: 'numeric' });

        if (!acc[dayKey]) {
          acc[dayKey] = { events: [], dayDisplay };
        }
        acc[dayKey].events.push(event);
      }
      return acc;
    }, {});

    return Object.entries(eventsByDay)
      .sort((a, b) => a[0].localeCompare(b[0]))
      .map(([key, { dayDisplay, events }]) => ({
        title: dayDisplay,
        data: events,
      }));
  }, [locationFilter]);

  const updateEventsData = useCallback(() => {
    const groupedEvents = groupEventsByDay(schedule);
    setEventsData(groupedEvents);
  }, [schedule, groupEventsByDay]);

  useEffect(() => {
    const locationSet = new Set(['All']);
    schedule.forEach(event => locationSet.add(event.location));
    setLocations([...locationSet]);

    updateEventsData();
  }, [schedule, updateEventsData]);

  useEffect(() => {
    updateEventsData();
  }, [locationFilter, updateEventsData]);

  const toggleDayCollapse = (dayKey) => {
    setCollapsedDays((prevState) => ({
      ...prevState,
      [dayKey]: !prevState[dayKey],
    }));
  };

  const openEditModal = (event) => {
    setSelectedEvent(event);
    setIsEditModalOpen(true);
    document.body.style.overflow = 'hidden';
  };

  const closeEditModal = () => {
    setSelectedEvent(null);
    setIsEditModalOpen(false);
    document.body.style.overflow = 'auto';
  };

  const openDeleteModal = (event) => {
    setSelectedEvent(event);
    setIsDeleteModalOpen(true);
    document.body.style.overflow = 'hidden';
  };

  const closeDeleteModal = () => {
    setSelectedEvent(null);
    setIsDeleteModalOpen(false);
    document.body.style.overflow = 'auto';
  };

  const handleModifyEvent = async (updatedEvent) => {
    try {
      await ModifyEvent(updatedEvent.id, updatedEvent);
      closeEditModal();
      setEventsData((prevEvents) =>
        prevEvents.map((event) => (event.id === updatedEvent.id ? updatedEvent : event))
      );
    } catch (error) {
      console.error('Error modifying event: ', error);
    }
  };

  const confirmDeleteEvent = () => {
    handleDeleteEvent(selectedEvent.id);
    closeDeleteModal();
  };

  return (
    <div className="schedule-container">
      <h3>Your Current Schedule</h3>
      {locations.length > 1 && (
        <div className="filter-container">
          {locations.map((location) => (
            <button
              key={location}
              className={`filter-button ${locationFilter === location ? 'active' : ''}`}
              onClick={() => setLocationFilter(location)}
            >
              {location}
            </button>
          ))}
        </div>
      )}
      <ul className="event-list">
        {eventsData.map((section) => (
          <li key={section.title} className="event-section">
            <h2 className="section-header" onClick={() => toggleDayCollapse(section.title)}>
              {section.title}
              <span
                className={`arrow ${collapsedDays[section.title] ? 'collapsed' : ''}`}
              >
                ▼
              </span>
            </h2>
            {!collapsedDays[section.title] && (
              <ul className="section-events">
                {section.data.map((item, index) => (
                  <li key={index} className="event-item">
                    <div className="event-details">
                      <div style={{fontWeight: 'bold', marginBottom: 5}}>{item.eventName}</div>
                      <div>{item.location}</div>
                      <div>{item.description}</div>
                    </div>
                    <div className="time-container">
                      {`${formatTimeTo12Hour(item.startTime)} - ${formatTimeTo12Hour(item.endTime)}`}
                    </div>
                    <div className="buttons-container">
                      <button className="edit-button" onClick={() => openEditModal(item)}>
                        Edit Event
                      </button>
                      <button className="delete-button" onClick={() => openDeleteModal(item)}>
                        Delete Event
                      </button>
                    </div>
                  </li>
                ))}
              </ul>
            )}
          </li>
        ))}
      </ul>
      <Modal isOpen={isEditModalOpen} onClose={closeEditModal} style={{ width: '30%' }}>
        {selectedEvent && (
          <EventCreationForm
            handleAddEventToSchedule={handleModifyEvent}
            locations={locations}
            startDate={startDate}
            endDate={endDate}
            initialEvent={selectedEvent}
          />
        )}
      </Modal>
      <Modal isOpen={isDeleteModalOpen} onClose={closeDeleteModal} style={{ width: '30%' }}>
        <h3>Are you sure you want to delete this event? This cannot be undone.</h3>
        <div>
          <button className="confirm-button" onClick={confirmDeleteEvent}>
            Yes
          </button>
          <button className="cancel-button" onClick={closeDeleteModal}>
            No
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default ScheduleList;
