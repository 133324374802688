import React from 'react';
import '../../styles/components/update-card.css'; // Import the CSS file for the Update component

const Update = ({ title, content, date }) => {
  return (
    <div className="update-container">
      <h3 className="update-title">{title}</h3>
      <p className="update-content">{content}</p>
      <p className="update-date">{date}</p>
    </div>
  );
};

export default Update;
