import { z } from "zod";


const SocialLinkSchema = z.object({
  name: z.string().min(1, "Platform name is required"),
  url: z.string().url("Invalid URL format"),
});

export const ParticipantSchema = z.object({
  id: z.string(),
  name: z.string().min(5, "Name must be at least 5 characters long.").max(30),
  userID: z.string(),
  email: z.string().email("Invalid email format"),
  website: z.string().url().optional(),
  description: z.string().max(120, "Description must be less than 120 characters long.").optional(),
  gatheringReferenceIDs: z.array(z.string()).optional(),
  referenceID: z.string(),
  imagePath: z.string().url().optional(), // New field for the image path
  socialLinks: z.array(SocialLinkSchema).optional(), // Array of social link objects

});

export const PartialParticipantSchema = ParticipantSchema.omit({
  id: true,
  userID: true,
  referenceID: true,
});
