// src/data/schemas/Things/AnnouncementSchema.ts
import { z } from 'zod';

export const AnnouncementSchema = z.object({
  id: z.string().optional(), // Assuming ID is generated elsewhere and optional when creating new
  title: z.string()
    .min(5, "Title must be at least 5 characters long.")
    .max(100, "Title must be less than 100 characters long."),
  content: z.string()
    .min(10, "Content must be at least 5 characters long.")
    .max(500, "Content must be less than 500 characters long."),
  userID: z.string(),
  gatheringID: z.string(),
  createdAt: z.date().optional(),
});

export type Announcement = z.infer<typeof AnnouncementSchema>;
