// FetchMapImage.js
import { getStorage, ref, getDownloadURL, listAll } from 'firebase/storage'; // Add 'listAll'

const FetchMapImage = async (gatheringId) => {
    const storage = getStorage();
    const mapFolderRef = ref(storage, `gatherings/${gatheringId}/map`);

    try {
        const res = await listAll(mapFolderRef);
        //console.log("Storage list response: ", res);  // Log the response from listAll
        if (res.items.length > 0) {
            const url = await getDownloadURL(res.items[0]);

            // console.log("Map image URL: ", url);  // Log the URL retrieved
            return url;
        } else {
            console.log("No images found in the map folder.");
            return null;
        }
    } catch (error) {
        console.error("Failed to fetch map image: ", error);
        return null; // Return null if there's an error
    }
};

export default FetchMapImage;
