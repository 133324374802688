import React from "react";
import "../../styles/components/gathering-customization-form-component.css";
import "../../styles/components/image-uploads.css";

const MapTab = ({ mapImage, handleMapChange }) => {
  return (
    <div className="input-container">
      <h3 className="tab-title">Event Map</h3>
      <div className="input-container">
        <h3>Upload a new map image:</h3>
        
        <label className="upload-button-label" htmlFor="map-upload">
          Upload Image
        </label>
        <input
          id="map-upload"
          type="file"
          onChange={handleMapChange}
          accept="image/*"
          className="upload-button"
        />

        {mapImage && (
          <img className="map-image" src={mapImage} alt="Gathering Map" />
        )}
      </div>
    </div>
  );
};

export default MapTab;
