import { collection, doc, setDoc, getDocs, query, where } from 'firebase/firestore';
import { firestore } from '../config/FirebaseConfig'; 
import { EventSchema } from '../data/validationSchemas/EventSchema';
import toPascalCase from '../logic/toPascalCase'; 

type EventInput = {
  eventName: string;
  id: string;
  description: string;
  location: string;
  startDate: string; 
  startTime: string;
};
const CreateEvent = async (input: EventInput, gatheringID: string): Promise<string | null> => {
  console.log("Event data:", JSON.stringify(input));
  const existingEventsQuery = query(
    collection(firestore, "Events"),
    where("EventName", "==", input.eventName),
    where("StartDate", "==", input.startDate),
    where("StartTime", "==", input.startTime),
    where("Location", "==", input.startTime),
    where("GatheringID", "==", gatheringID)
  );
  const existingEvents = await getDocs(existingEventsQuery);
  if (!existingEvents.empty) {
    console.log("Event already exists, skipping creation");
    return null; // If an event with the same unique properties exists, skip creation
  }
  try {
    const newDocRef = doc(collection(firestore, "Events"));
    const customId = newDocRef.id;

    const eventDataForValidation = {
      ...input,
      id: customId,
      gatheringID, // Pass the gathering ID
    };

    const result = EventSchema.safeParse(eventDataForValidation);
    if (!result.success) {
      console.error("Validation failed", result.error);
      return null;
    }

    const firestoreData = {
      ...toPascalCase(result.data),
      GatheringID: gatheringID, 
    };

    await setDoc(newDocRef, firestoreData); // Ensure endTime is passed as string

    console.log("Event document created with ID:", customId);
    return customId;
  } catch (error) {
    console.error("Error creating event document:", error);
    return null;
  }
};

export default CreateEvent;
