import React, { useState } from "react";
import CreateAnnouncement from "../../api/CreateAnnouncement"; // Adjust the import path as needed
import { auth } from "../../config/FirebaseConfig";
import "../../styles/components/announcement-form.css"; // Import the new styles
import { z } from "zod";
import { AnnouncementSchema } from "../../data/validationSchemas/AnnouncementSchema";
import Modal from "../Modal/Modal";

const AnnouncementForm = ({ gatheringID, onNewAnnouncement }) => {
  const [formData, setFormData] = useState({
    title: "",
    content: "",
    userID: auth.currentUser ? auth.currentUser.uid : "",
    gatheringID: gatheringID,
  });

  const [errors, setErrors] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);

  const validateInputs = () => {
    try {
      AnnouncementSchema.parse(formData);
      setErrors({});
      return true;
    } catch (error) {
      if (error instanceof z.ZodError) {
        const newErrors = error.errors.reduce((acc, err) => {
          acc[err.path[0]] = err.message;
          return acc;
        }, {});
        setErrors(newErrors);
      }
      return false;
    }
  };

  const handleOpenModal = (e) => {
    e.preventDefault(); // Prevent form from refreshing the page on submit

    if (validateInputs()) {
      setIsModalOpen(true);
    } else {
      alert("Please correct the errors before submitting.");
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleConfirmModal = async () => {
    setIsModalOpen(false);
    try {
      const announcementID = await CreateAnnouncement(formData);
      if (announcementID) {
        console.log(`Announcement published with ID: ${announcementID}`);
        // Optionally clear the form or give user feedback
        setFormData({
          title: "",
          content: "",
          userID: auth.currentUser ? auth.currentUser.uid : "",
          gatheringID: gatheringID,
        });
        onNewAnnouncement({
          ...formData,
          id: announcementID,
          createdAt: new Date(),
        });
      }
    } catch (error) {
      console.error("Unexpected error:", error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  return (
    <div className="input-container">
      <form className="announcement-form" onSubmit={handleOpenModal}>
        <h3>Publish a New Announcement</h3>
        <input
          type="text"
          name="title"
          value={formData.title}
          placeholder="Title"
          onChange={handleInputChange}
          required
        />
        {errors.title && <p className="error-message">{errors.title}</p>}
        <textarea
          name="content"
          value={formData.content}
          placeholder="Announcement content"
          onChange={handleInputChange}
          required
        />
        {errors.content && <p className="error-message">{errors.content}</p>}
        <button type="submit">Publish Announcement</button>
      </form>
      <Modal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
      >
        <h3>Confirm Announcement</h3>
        <p>Are you sure you want to publish this announcement?</p>
        <button type="submit" onClick={handleConfirmModal}>Publish Announcement</button>

      </Modal>
    </div>
  );
};

export default AnnouncementForm;
