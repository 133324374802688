// FetchEvents.js
import { collection, query, where, getDocs } from 'firebase/firestore';
import { firestore } from '../config/FirebaseConfig'; // Adjust the path as needed

// Assuming toCamelCase is correctly implemented to handle array of objects
import toCamelCase from '../logic/toCamelCase'; // Adjust the import path as needed
import { Event } from '../data/classes/Event';

const FetchEvents = async (gatheringID: string): Promise<Event[]> => {
  try {
    const eventsQuery = query(collection(firestore, "Events"), where("GatheringID", "==", gatheringID));
    const querySnapshot = await getDocs(eventsQuery);
    const events = querySnapshot.docs.map(doc => toCamelCase({ id: doc.id, ...doc.data() }) as Event);
    
    return events;
  } catch (error) {
    console.error("Error fetching events: ", error);
    return [];
  }
};
export default FetchEvents;