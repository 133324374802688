import { collection, query, where, getDocs } from 'firebase/firestore';
import { firestore, auth } from '../config/FirebaseConfig';
import toCamelCase from '../logic/toCamelCase';
import { Gathering } from '../data/classes/Gathering';

const FetchUserGatherings = async (): Promise<Gathering[]> => {
  const userGatherings: Gathering[] = [];

  if (auth.currentUser) {
    const hostQuery = query(
      collection(firestore, "Gatherings"),
      where("HostID", "==", auth.currentUser.uid)
    );

    const teamQuery = query(
      collection(firestore, "Gatherings"),
      where("TeamEmails", "array-contains", auth.currentUser.email)
    );

    const [hostSnapshot, teamSnapshot] = await Promise.all([
      getDocs(hostQuery),
      getDocs(teamQuery),
    ]);

    // Combine results from both queries
    hostSnapshot.forEach(doc => {
      userGatherings.push(toCamelCase({ id: doc.id, ...doc.data() }) as Gathering);
    });

    teamSnapshot.forEach(doc => {
      const gathering = toCamelCase({ id: doc.id, ...doc.data() }) as Gathering;
      // Avoid duplicates
      if (!userGatherings.some(g => g.id === gathering.id)) {
        userGatherings.push(gathering);
      }
    });
  }

  return userGatherings;
};

export default FetchUserGatherings;
