import { getStorage, ref, listAll, deleteObject } from "firebase/storage";

const DeleteExistingImage = async (entityID, entityType) => {
  const storage = getStorage();

  let baseDirectory;

  // Map entityType to the correct directory
  switch (entityType.toLowerCase()) {
    case "artist":
      baseDirectory = `artistImages`; // Directory for artist images
      break;
    case "vendor":
      baseDirectory = `vendorImages`; // Directory for vendor images
      break;
    case "gathering-map":
      baseDirectory = `gatherings/${entityID}/map`; // Directory for gathering map images
      break;
    case "gathering-logo":
      baseDirectory = `gatherings/${entityID}/logo`; // Directory for gathering logo images
      break;
    default:
      throw new Error(`Invalid entity type: ${entityType}`);
  }

  // Construct the folder reference
  const folderRef =
    entityType === "artist" || entityType === "vendor"
      ? ref(storage, `${baseDirectory}/${entityID}`) // Artists and Vendors include the entityID as subfolder
      : ref(storage, baseDirectory); // Gatherings already include entityID in the path

  try {
    const res = await listAll(folderRef); // List all items in the directory
    const deletePromises = res.items.map((item) => deleteObject(item)); // Create delete promises for each item
    await Promise.all(deletePromises); // Execute all delete promises
    console.log(`Successfully deleted existing images in ${folderRef.fullPath}.`);
  } catch (error) {
    if (error.code === "storage/object-not-found") {
      console.log(`No images found in ${folderRef.fullPath}.`);
    } else {
      console.error(`Error deleting images in ${folderRef.fullPath}:`, error);
    }
  }
};

export default DeleteExistingImage;
