import Header from "../../components/Header/Header";

function LandingPage() {
  return (
    <div>
      test
    </div>
  );
}

export default LandingPage;
