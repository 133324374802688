import React, { useState } from "react";
import Modal from "../Modal/Modal"
import "../../styles/components/support-modal.css"; // Your custom styles for the modal


const SupportModal = ({ showSupportModal, setShowSupportModal }) => {
  const [formData, setFormData] = useState({
    subject: "Support Request",
    message: "",
  });

  const handleClose = () => setShowSupportModal(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    window.location.href = `mailto:support@itiniri.com?subject=${encodeURIComponent(
      formData.subject
    )}&body=${encodeURIComponent(formData.message)}`;
    handleClose();
  };

  return (
    <Modal isOpen={showSupportModal} onClose={handleClose}>
      <div className="modal-content">
        <h2>Contact Support</h2>
        <p>We’re here to help! Please describe your issue below:</p>
        
        <form onSubmit={handleSubmit} className="support-form">
          <label>
            Subject:
            <input
              type="text"
              name="subject"
              value={formData.subject}
              onChange={handleInputChange}
              required
            />
          </label>
          <label>
            Message:
            <textarea
              name="message"
              value={formData.message}
              onChange={handleInputChange}
              rows="5"
              required
            />
          </label>
          <div className="modal-buttons">
            <button type="submit" className="send-button">Send Email</button>
            <button type="button" className="close-button" onClick={handleClose}>
              Close
            </button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default SupportModal;
