import React, { useState } from 'react';
import { auth, firestore } from '../../config/FirebaseConfig';
import { signInWithEmailAndPassword, createUserWithEmailAndPassword, sendEmailVerification, sendPasswordResetEmail } from 'firebase/auth';
import { doc, setDoc, updateDoc } from 'firebase/firestore';
import Header from '../../components/Header/Header';
import '../../styles/views/auth-view.css';
import '../../styles/components/input-component.css';

const AuthView = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState('');

  const signIn = async () => {
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;
  
      // Reload the user data to get the latest email verification status
      await user.reload();
  
      // Check if email is verified and update Firestore if necessary
      if (user.emailVerified) {
        setMessage('Successfully signed in and email verified!');
      } else {
        setMessage('Successfully signed in. Please verify your email.');
      }
    } catch (error) {
      console.error(error);
      setMessage('Error signing in. Please check your credentials.');
    }
  };

  const signUp = async () => {
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;
  
      // Send verification email
      await sendEmailVerification(user);
  
      // Create Firestore document
      await setDoc(doc(firestore, 'Users', user.uid), {
        email: user.email,
        name: '',
        admin: false
      });
  
      setMessage('Verification email sent. Please check your inbox.');
    } catch (error) {
      console.error('Error creating user:', error);
      alert("Error creating user. Please try again or contact support.");
    }
  };

  const resetPassword = async () => {
    try {
      await sendPasswordResetEmail(auth, email);
      setMessage('Password reset email sent. Please check your inbox.');
    } catch (error) {
      console.error('Error sending password reset email:', error);
      setMessage('Error sending password reset email. Please try again.');
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    signIn();
  };

  return (
    <div className="auth-container">
      <form className="input-group" onSubmit={handleSubmit}>
        <input
          className="custom-input"
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Email"
          required
        />
        <input
          className="custom-input"
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Password"
          required
        />
        <button type="submit">Sign In</button>
        <button type="button" onClick={signUp}>Create Account</button>
        
        {/* Forgot Password styled as a link */}
        <button
          type="button"
          onClick={resetPassword}
          style={{
            background: 'none',
            border: 'none',
            color: '#2183A6',
            cursor: 'pointer',
            textDecoration: 'underline',
            padding: '0',
            marginTop: '10px'
          }}
        >
          Forgot Password?
        </button>

        {message && <p className="message">{message}</p>}
      </form>
    </div>
  );
};

export default AuthView;



// const AuthView = () => {
//   const [email, setEmail] = useState('');
//   const [password, setPassword] = useState('');
//   const [message, setMessage] = useState('');
//   const [recaptchaToken, setRecaptchaToken] = useState(null);

//   const onRecaptchaChange = (token) => {
//     setRecaptchaToken(token);
//   };

//   const signIn = async () => {
//     try {
//       await signInWithEmailAndPassword(auth, email, password);
//       setMessage("Successfully signed in.");
//     } catch (error) {
//       console.error(error);
//       setMessage("Error signing in. Please check your credentials.");
//     }
//   };

//   const signUp = async () => {
//     if (!recaptchaToken) {
//       setMessage("Please complete the reCAPTCHA to proceed.");
//       return;
//     }

//     try {
//       // Verify reCAPTCHA token with Firebase function
//       const verifyRecaptcha = httpsCallable(functions, 'verifyRecaptcha');
//       const result = await verifyRecaptcha({ token: recaptchaToken });

//       if (result.data.success) {
//         // Create user if reCAPTCHA is verified
//         const userCredential = await createUserWithEmailAndPassword(auth, email, password);
//         const user = userCredential.user;
//         await sendEmailVerification(user);
//         await setDoc(doc(firestore, 'Users', user.uid), { email: user.email, name: '', admin: false, verified: false });
//         setMessage('Verification email sent. Please check your inbox.');
//       } else {
//         setMessage('reCAPTCHA verification failed. Please try again.');
//       }
//     } catch (error) {
//       console.error('Error creating user:', error);
//       setMessage("Error creating user. Please try again or contact support.");
//     }
//   };

//   return (
//     <div className="auth-container">
//       <form className="input-group" onSubmit={(e) => e.preventDefault()}>
//         <input
//           className="custom-input"
//           type="email"
//           value={email}
//           onChange={(e) => setEmail(e.target.value)}
//           placeholder="Email"
//           required
//         />
//         <input
//           className="custom-input"
//           type="password"
//           value={password}
//           onChange={(e) => setPassword(e.target.value)}
//           placeholder="Password"
//           required
//         />

//         {/* reCAPTCHA - Only necessary for the sign-up flow */}
//         <ReCAPTCHA
//           sitekey="YOUR_RECAPTCHA_SITE_KEY" // Replace with your Site Key
//           onChange={onRecaptchaChange}
//         />

//         <button type="button" onClick={signIn}>Login</button>
//         <button type="button" onClick={signUp}>Sign Up</button>

//         {message && <p className="message">{message}</p>}
//       </form>
//     </div>
//   );
// };

