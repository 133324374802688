const toCamelCase = (obj: any): any => {
  if (obj === null || typeof obj !== 'object') {
    return obj;
  }

  return Object.keys(obj).reduce((camelCaseObj: any, key: string) => {
    const camelCaseKey = key.charAt(0).toLowerCase() + key.slice(1);
    camelCaseObj[camelCaseKey] = toCamelCase(obj[key]);
    return camelCaseObj;
  }, {});
};

export default toCamelCase;
