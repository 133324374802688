import React, { useState } from "react";
import ModifyParticipantGatherings from "../../api/ModifyParticipantGatherings"; // Unified API for add/remove
import Modal from "../Modal/Modal";

const ParticipantTab = ({
  participantType,
  participants = [],
  gatheringID,
  gatheringReferenceID,
  updateParticipants, // Callback to update participants in the parent component
}) => {
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [currentParticipantReferenceID, setCurrentParticipantReferenceID] =
    useState(null);
  const [showAddModal, setShowAddModal] = useState(false);
  const [newParticipantReferenceID, setNewParticipantReferenceID] =
    useState("");

  // Confirm remove modal handlers
  const handleRemoveClick = (participantReferenceID) => {
    setCurrentParticipantReferenceID(participantReferenceID);
    setShowConfirmModal(true);
  };

  const confirmRemoval = async () => {
    if (!currentParticipantReferenceID) return;

    try {
      const result = await ModifyParticipantGatherings(
        currentParticipantReferenceID,
        gatheringReferenceID,
        participantType,
        "remove"
      );

      if (result.success) {
        alert(`${participantType} removed successfully.`);
        updateParticipants(
          participants.filter(
            (participant) =>
              participant.referenceID !== currentParticipantReferenceID
          )
        );
      } else {
        alert(`Failed to remove the ${participantType}.`);
      }
    } catch (error) {
      console.error(`Error removing ${participantType}:`, error);
      alert("An unexpected error occurred.");
    } finally {
      setShowConfirmModal(false);
      setCurrentParticipantReferenceID(null);
    }
  };

  const cancelRemoval = () => {
    setShowConfirmModal(false);
    setCurrentParticipantReferenceID(null);
  };

  // Add participant modal handlers
  const openAddModal = () => setShowAddModal(true);
  const closeAddModal = () => {
    setShowAddModal(false);
    setNewParticipantReferenceID("");
  };

  const handleAddParticipant = async () => {
    if (!newParticipantReferenceID) {
      alert("Please enter a Participant Reference ID.");
      return;
    }

    try {
      const result = await ModifyParticipantGatherings(
        newParticipantReferenceID,
        gatheringReferenceID,
        participantType,
        "add"
      );

      if (result.success) {
        alert(`${participantType} added successfully.`);
        // Append the new participant to the list (using placeholder details)
        updateParticipants([
          ...participants,
          { referenceID: newParticipantReferenceID, name: "New Participant" },
        ]);
        closeAddModal();
      } else {
        alert(`Failed to add the ${participantType}.`);
      }
    } catch (error) {
      console.error(`Error adding ${participantType}:`, error);
      alert("An unexpected error occurred.");
    }
  };

  const handleCopyToClipboard = () => {
    if (!gatheringReferenceID) {
      alert("No Reference ID available to copy!");
      return;
    }

    navigator.clipboard
      .writeText(gatheringReferenceID)
      .then(() => alert("Reference ID copied to clipboard!"))
      .catch(() => alert("Failed to copy Reference ID."));
  };

  return (
    <div className="input-container">
      <h3 className="tab-title">{participantType}s</h3>
      <p>
        Share the following {participantType} ID with your{" "}
        {participantType.toLowerCase()}s. They can use it to create an account
        and register with your event:
      </p>
      <p>{gatheringReferenceID}</p>
      <button
        onClick={handleCopyToClipboard}
        style={{ width: "50%", marginBottom: "10px" }}
      >
        Copy to Clipboard
      </button>
      <button
        onClick={openAddModal}
        style={{ width: "50%", marginBottom: "10px" }}
      >
        Add {participantType}
      </button>
      <div className="table-container">
        <table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Email</th>
              <th>Description</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {participants.map((participant) => (
              <tr key={participant.referenceID}>
                <td>{participant.name}</td>
                <td>{participant.email}</td>
                <td>{participant.description}</td>
                <td>
                  <button
                    onClick={() => handleRemoveClick(participant.referenceID)}
                  >
                    Remove
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {showConfirmModal && (
        <Modal isOpen={showConfirmModal} onClose={cancelRemoval}>
          <div>
            <h4>
              Are you sure you want to remove this{" "}
              {participantType.toLowerCase()}?
            </h4>
            <button onClick={confirmRemoval}>Yes</button>
            <button onClick={cancelRemoval}>No</button>
          </div>
        </Modal>
      )}
      {showAddModal && (
        <Modal isOpen={showAddModal} onClose={closeAddModal}>
          <h4>Add {participantType} by Reference ID</h4>
          <p>Contact the Participant for their unique Reference ID.</p>
          <input
            type="text"
            className="input-field"
            value={newParticipantReferenceID}
            onChange={(e) => setNewParticipantReferenceID(e.target.value)}
            placeholder={`Enter ${participantType} Reference ID`}
          />
          <button onClick={handleAddParticipant}>Submit</button>
        </Modal>
      )}
    </div>
  );
};

export default ParticipantTab;
