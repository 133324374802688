import { getFunctions, httpsCallable } from "firebase/functions";

const functions = getFunctions();

const ModifyParticipantGatherings = async (participantReferenceID, gatheringReferenceID, type, action) => {
  try {

    console.log("Payload being sent:", {
        participantReferenceID,
        gatheringReferenceID: gatheringReferenceID,
        type: type,
        action: action,
      });
      
    const modifyParticipantGatherings = httpsCallable(functions, "modifyParticipantGatherings");

    const result = await modifyParticipantGatherings({
        participantReferenceID,
        gatheringReferenceID,
        action, // 'add' or 'remove'
        type, // 'Vendor' or 'Artist'
      });

    return result.data; // Assuming the Firebase function returns { success: true }
  } catch (error) {
    console.error(`Error modifying ${type} and Gathering:`, error);
    throw error;
  }
};

export default ModifyParticipantGatherings;
