import { doc, updateDoc, arrayRemove } from "firebase/firestore";
import { firestore } from "../config/FirebaseConfig"; // Correct Firestore instance reference

const RemoveTeamMemberFromGathering = async (gatheringID, email) => {
  try {
    console.log("GatheringID:", gatheringID); // Debugging
    const gatheringRef = doc(firestore, "Gatherings", gatheringID);

    // Remove the email from the TeamEmails array
    await updateDoc(gatheringRef, { TeamEmails: arrayRemove(email) });
    
    console.log("Team member removed:", email);
    return { success: true };
  } catch (error) {
    console.error("Error removing team member from gathering:", error);
    return { success: false, message: error.message };
  }
};

export default RemoveTeamMemberFromGathering;
